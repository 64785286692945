import { get } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, memo } from 'react';
import {
  sanitizeFieldRestProps,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Badge } from '@/modules/ui/components/badge';
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/modules/ui/components/tooltip';

export interface DateTimeShowProps {
  className?: string;
  emptyText?: string;
  source: string;
  label?: string;
  showTime?: boolean;
  showDate?: boolean;
  showTZ?: boolean;
  side?: 'top' | 'bottom' | 'left' | 'right';
  timezone?: string;
}

export const DateTimeShow: FC<DateTimeShowProps> = memo((props) => {
  const {
    className,
    emptyText,
    showTime = true,
    showDate = true,
    showTZ = false,
    source,
    side = 'bottom',
    timezone,
    ...rest
  } = props;

  const translate = useTranslate();
  const record = useRecordContext(props);

  if (!showTime && !showDate) {
    throw new Error(
      '<DateTimeShow> cannot have showTime and showDate false at the same time',
    );
  }

  if (!record) {
    return null;
  }

  const value = get(record, source);
  if (value === null || value === '') {
    return emptyText ? (
      <Badge
        variant="default"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText && translate(emptyText, { _: emptyText })}
      </Badge>
    ) : null;
  }

  const utcDate = DateTime.fromISO(value, { zone: 'utc' });
  const date = timezone ? utcDate.setZone(timezone) : utcDate.setZone('local');
  const localDate = timezone ? utcDate.setZone('local') : null;

  let dateString = '';
  if (showTime && showDate) {
    dateString = date.toLocaleString(DateTime.DATETIME_MED);
  } else if (showDate) {
    dateString = date.toLocaleString(DateTime.DATE_MED);
  } else if (showTime) {
    dateString = date.toLocaleString(DateTime.TIME_SIMPLE);
  }

  let tzAbbreviation = null;
  if (showTime && showTZ && !timezone) {
    tzAbbreviation = date.toFormat('ZZZZ');
  }

  let tooltipContent = null;
  if (timezone) {
    tooltipContent = (
      <div className="flex flex-row items-center gap-1">
        <span className="text-xs font-bold">Your Local Time:</span>
        {localDate?.toLocaleString(DateTime.DATETIME_MED)}
      </div>
    );
  } else {
    tooltipContent = null;
  }

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            {dateString}
            {tzAbbreviation && (
              <Badge variant="outline" className={`${className} ml-1 px-1.5`}>
                {tzAbbreviation}
              </Badge>
            )}
          </div>
        </TooltipTrigger>
        {tooltipContent && (
          <TooltipContent className="w-fit p-2" align="start" side={side}>
            {tooltipContent}
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
});
