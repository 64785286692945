import { Stack } from '@mui/material';
import React from 'react';
import {
  DateInput,
  DeleteButton,
  NumberInput,
  ReferenceField,
  TextInput,
  TextField,
  BooleanInput,
  FormDataConsumer,
  useRecordContext,
  required,
  usePermissions,
} from 'react-admin';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { ICD10ArrayInput } from '@/modules/icd10/components/ICD10Input';
import { get } from 'lodash';
const Content = () => {
  return (
    <Stack direction="column" gap={0} mt={1}>
    <p className="text-sm">
        Patient:{' '}
        <ReferenceField
          label="Patient"
          source="patient_id"
          reference="users"
          link={(record) => `/patients/${record.id}/show`}
        >
          <TextField source="full_name" />
        </ReferenceField>
      </p>
      <DateInput source="date_of_service" helperText={false} />
      <DateInput source="end_date_of_service" helperText={false} />
      <DateInput
        source="suggested_billing_date"
        helperText={false}
        validate={required()}
      />
      <TextInput source="cpt_code" label="CPT code" helperText={false} />
      <NumberInput source="units" helperText={false} />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.source === 'manual') {
            return <TextInput source="note" label="Note" helperText={false} />;
          }
          return null;
        }}
      </FormDataConsumer>
      <TextInput source="billing_provider" helperText={false} />
      <ICD10ArrayInput />
      <BooleanInput source="submitted" helperText={false} />
    </Stack>
  );
};

export const EditClaimItemButton = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  if (!record || record.submitted) {
    return null;
  }

  if (!!!get(permissions, 'claim_items.update')) {
    return null;
  }

  return (
    <EditInDialogButton
      title="Edit claim item"
      resource="claim-items"
      variant="outline"
      deleteButton={
        <DeleteButton
          resource="claim-items"
          redirect={false}
          confirmTitle="Delete claim?"
        />
      }
      refreshOnSuccess={true}
    >
      <Content />
    </EditInDialogButton>
  );
};
