import React from 'react';
import { useOrganization } from '../../../organizations/components/OrganizationProvider';
import { cn } from '@/modules/ui/utils/cn';
import Logo from '@/modules/layout/components/Logo.svg?react';
import { useIsDemo } from '@/modules/demo/components/DemoProvider';
import { Badge } from '@/modules/ui/components/badge';
import { useSidebar } from '@/modules/ui/components/sidebar';

export function SidebarLogo() {
  const { organization } = useOrganization();
  const { open } = useSidebar();
  const { isDemo } = useIsDemo();

  if (open) {
    return (
      <div className="flex flex-col w-full">
        <div className={cn('flex flex-row justify-start items-center gap-1 ')}>
          <Logo className="object-cover h-8 w-8 p-1" />
          <p className="text-sm">
            {organization ? organization.name : 'Vironix Health'}
          </p>
        </div>
        {isDemo && (
          <Badge className="bg-yellow-500 text-center w-fit mx-auto hover:bg-yellow-500">
            Demo Mode
          </Badge>
        )}
      </div>
    );
  }

  if (!open) {
    return (
      <div className="flex flex-col ">
        <Logo className="object-cover h-8 w-8 p-1 mx-auto" />
      </div>
    );
  }
}
