import {
  Bug,
  Building,
  Building2,
  Clock,
  Contact,
  CreditCard,
  FileQuestion,
  FileText,
  HelpCircle,
  Home,
  Hourglass,
  ListTodo,
  Phone,
  RadioTower,
  Ticket,
  UserPlus,
  Users,
} from 'lucide-react';

interface MenuConfig {
  name: string;
  resources: Resource[];
  collapsible: boolean;
  defaultOpen: boolean;
}

interface Resource {
  name: string;
  icon: any;
  link: string;
  permissions?: string[] | null;
  roles?: string[] | null;
  section?: string;
  type?: string;
  internal_user_only?: boolean;
  dev_only?: boolean;
  attendanceTracking?: boolean;
}

export const menuConfig: Array<MenuConfig> = [
  {
    name: 'General',
    collapsible: false,
    defaultOpen: true,
    resources: [
      {
        name: 'Home',
        icon: Home,
        link: '/',
      },
      {
        name: 'My Attendance',
        icon: Clock,
        link: '/attendance/my',
        permissions: ['attendances.use'],
        internal_user_only: true,
        attendanceTracking: true,
      },
    ],
  },
  {
    name: 'Patient Management',
    collapsible: true,
    defaultOpen: true,
    resources: [
      {
        name: 'Patient Queue',
        icon: Contact,
        link: 'patient-queue',
        permissions: ['patients.queue'],

      },
      {
        name: "Enrollment Queue",
        icon: UserPlus,
        link: 'enrollment',
        permissions: ['patients.list'],
        internal_user_only: true,
      },
      {
        name: 'Patient List',
        icon: Contact,
        link: 'patients',
        permissions: ['patients.list'],
      },]
    },
    {
      name: 'Clinical Operations',
      collapsible: true,
      defaultOpen: true,
      resources: [
      {
        name: "Monitoring",
        icon: Hourglass,
        link: 'monitoring',
        permissions: ['monitoring_progress.list'],
        internal_user_only: true,
      },
      {
        name: 'Tasks',
        icon: ListTodo,
        link: 'tasks',
        permissions: ['tasks.list'],
      },
      {
        name: 'Reports',
        icon: FileText,
        link: 'reports',
        permissions: ['reports.list'],
        internal_user_only: true,
      },
      {
        name: 'Voice Calls',
        icon: Phone,
        link: 'voice-calls',
        permissions: ['voice_calls.list'],
      },
    ],
  },
  {
    name: 'Management',
    collapsible: true,
    defaultOpen: true,
    resources: [
      {
        name: 'Attendance',
        icon: Clock,
        link: 'attendances',
        roles: ['superadmin', 'internal_admin'],
        permissions: ['attendances.list'],
      },
      {
        name: 'Devices',
        icon: RadioTower,
        link: 'devices',
        permissions: ['devices.list'],
      },

      {
        name: 'Locations',
        icon: Building,
        link: 'locations',
        permissions: ['locations.list'],
        internal_user_only: true,
      },
      {
        name: 'Claims',
        icon: Ticket,
        link: 'claim-items',
        permissions: ['claim_items.list'],
      },

      {
        name: 'Users',
        icon: Users,
        link: 'users',
        permissions: ['users.list', 'users.create'],
      },
    ],
  },
  {
    name: 'Administrative Tools',
    collapsible: true,
    defaultOpen: false,
    resources: [
      {
        name: 'Internal Users',
        icon: Users,
        link: 'internal-users',
        permissions: ['internal_users.list', 'internal_users.update'],
        internal_user_only: true,
      },
      {
        name: 'Providers',
        icon: Building2,
        link: 'providers',
        permissions: ['tenants.list', 'tenants.update'],
        internal_user_only: true,
      },
      {
        name: 'Audit Logs',
        icon: FileText,
        link: 'audit-logs',
        roles: ['superadmin'],
        permissions: ['audit_logs.list'],
      },
      {
        name: 'Testing',
        icon: Bug,
        link: 'testing',
        roles: ['superadmin'],
        section: 'Administrative',
        internal_user_only: true,
        dev_only: true,
      },
      {
        name: 'Billing',
        icon: CreditCard,
        link: 'billing',
        permissions: ['tenants.invoices'],
        section: 'Administrative',
        internal_user_only: false,
      }
    ],
  },
  {
    name: 'Customer Service',
    collapsible: true,
    defaultOpen: false,
    resources: [
      {
        name: 'Support Tickets',
        icon: FileQuestion,
        link: 'support-tickets',
        permissions: ['support_tickets.list'],
        internal_user_only: true,
      },
      {
        name: 'Device Issues',
        icon: Bug,
        link: 'device-issues',
        permissions: ['device_activities.list'],
        internal_user_only: true,
      }, 
    ]
  },
  {
    name: 'Help',
    collapsible: false,
    defaultOpen: false,
    resources: [

      {
        name: 'Help Center',
        icon: HelpCircle,
        link: 'https://vironix.notion.site/Help-Center-a26695ee626c4a0895b34f67b14caea4',
        permissions: null,
        section: '',
        type: 'external',
      },
    ],
  }
];
