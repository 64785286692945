import { monitoringActivityTypes } from '@/modules/monitoring-activity/constants/monitoringActivityTypes';
import { serviceInstanceTypesAbbreviated } from '@/modules/patients/constants/serviceInstanceTypesAbbreviated';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { get, map } from 'lodash';
import React from 'react';
import {
  DeleteWithUndoButton,
  required,
  useGetOne,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import IncompatibleBillingWarning from './IncompatibleBillingWarning';

import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';
import { YesNoRadioInput } from '@/modules/ui/inputs/yes-no-radio-input';
import { filter } from 'lodash';
import { ElapsedTime } from './ElapsedTime';
import DateTimeProviderTZInput from '@/modules/ui/inputs/date-time-provider-tz-input';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { ReferenceSelectInput } from '@/modules/ui/inputs/reference-select-input';
import { DateTimeProviderTZField } from '@/modules/ra-ui/components/DateTimeProviderTZField';
import { AutocompleteInput } from '@/modules/ui/inputs/autocomplete-input2';
import { filterUserName } from '@/utils/filterUserName';

const MonitoringActivitySelectType = () => {
  const record = useRecordContext();
  const serviceInstanceId = useWatch({ name: 'service_instance_id' });
  const { data, isLoading } = useGetOne(
    'service-instances',
    { id: serviceInstanceId },
    { enabled: !!serviceInstanceId },
  );

  const monitoringActivityTypeChoices = map(
    monitoringActivityTypes,
    (v, k) => ({ name: v, id: k }),
  );

  let choices = monitoringActivityTypeChoices;
  if (data) {
    choices = filter(monitoringActivityTypeChoices, (v) =>
      v.id.startsWith(data.type),
    );
    choices.push({
      name: 'Note',
      id: 'note',
    });
  } else {
    choices = [
      {
        name: 'Note',
        id: 'note',
      },
    ];
  }

  return (
    <div className="flex flex-col gap-1 pb-2">
      <SelectInput
        source="type"
        choices={choices}
        fullWidth
        helperText="Specify which service code this work belongs to."
        disabled={isLoading}
        validate={required()}
      />
      <IncompatibleBillingWarning
        patientId={record?.patient_id}
        hasDevice={null}
        lastDeviceMeasurement={null}
      />
    </div>
  );
};

export function MonitoringActivityEditDialog(props) {
  const record = useRecordContext();
  const { tenant_id } = props;
  const { permissions } = usePermissions();

  if (record.type === 'activity') {
    return null;
  }

  const isEMRReviewNote = record.type === 'emr_review_note';

  return (
    <EditInDialogButton
      title={`Edit ${isEMRReviewNote ? 'EMR Review Note' : 'monitoring activity'} (#${record?.id})`}
      deleteButton={<DeleteWithUndoButton redirect={false} />}
      resource="monitoring-activities"
      dialogVariant="shadcn"
      stopPropagation={true}
      closeOnClickOutside={false}
    >
      {isEMRReviewNote ? (
        <div className="flex flex-col gap-2 w-max">
          <AutocompleteInput
            label="Performed by"
            source="practitioner_id"
            optionText="full_name"
            reference="users"
            filter={{
              'is_active[eq]': true,
              'is_provider[eq]': true,
              'tenant_id[in]': record.user?.tenant_id,
            }}
            filterToQuery={filterUserName}
            validate={required()}
          />
          <DateTimeProviderTZInput
            source="reviewed_on"
            label="Performed on"
            tenant_id={tenant_id}
            validate={required()}
          />
          <YesNoRadioInput
            label="Has there been a two-way interaction with the patient?"
            source="patient_contacted"
            validate={required()}
          />
          <TextAreaInput
            label="Details"
            source="description"
            validate={required('Notes are required.')}
            minRows={4}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-2 w-max">
          <div className="flex flex-row gap-1">
            <p>Created on</p>
            <DateTimeProviderTZField
              source="created_on"
              label="Date"
              tenant_id={tenant_id}
              showTZ
            />
          </div>
          <ElapsedTime />
          {['admin', 'superadmin', 'internal_admin'].includes(
            get(permissions, 'role', ''),
          ) && (
            <DateTimeProviderTZInput
              source="reviewed_on"
              label="Date"
              tenant_id={tenant_id}
              validate={required()}
            />
          )}
          <div className="flex flex-row gap-1">
            <ReferenceSelectInput
              label="Care Program"
              optionText={(record) =>
                get(serviceInstanceTypesAbbreviated, record.type, record.type)
              }
              filter={{
                'is_active[eq]': true,
                'patient_id[eq]': record.patient_id,
              }}
              reference="service-instances"
              source="service_instance_id"
            />

            <MonitoringActivitySelectType />
          </div>
          <div className="flex flex-col gap-2 py-2">
            <YesNoRadioInput
              label="Has physiological data been reviewed?"
              source="data_reviewed"
              validate={required()}
            />
            <YesNoRadioInput
              label="Was there been a two-way interaction with the patient?"
              source="patient_contacted"
              validate={required()}
            />
          </div>
          <TextAreaInput
            label="Notes"
            source="description"
            validate={required('Notes are required.')}
            minRows={4}
          />
        </div>
      )}
    </EditInDialogButton>
  );
}
