import { InvoicesList } from '@/modules/organization-settings/components/InvoiceList';
import { useOrganization } from '@/modules/organizations/components/OrganizationProvider';
import { Card, CardContent } from '@/modules/ui/components/card';
import React from 'react';
export const BillingPage = () => {
  const { organization } = useOrganization();

  return (
    <div className="container mx-auto py-6">
      <h1 className="text-2xl font-bold mb-6">Billing</h1>
      <Card>
        <CardContent className="mt-4">
          <div className="flex flex-col gap-2 ">
            <p className="text-lg font-medium">Invoices</p>

            <InvoicesList providerId={organization?.id} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BillingPage;
