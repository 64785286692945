import { useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { CheckCircle, XCircle } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import React from 'react';
export const PatientEmailShow = () => {
  const record = useRecordContext();
  const email = get(record, 'user.email');
  const isVerified = get(record, 'user.is_email_verified');

  if (!email) {
    return <span>Not set</span>;
  }

  return (
    <div className="flex items-center gap-2">
      <span>{email}</span>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger className="cursor-default">
            {isVerified ? (
              <CheckCircle className="h-4 w-4 text-green-500" />
            ) : (
              <XCircle className="h-4 w-4 text-red-500" />
            )}
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {isVerified
                ? 'Email address is verified'
                : 'Email address is not verified'}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
