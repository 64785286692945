import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { filterTenantName } from '@/utils/filterTenantName';
import inflection from 'inflection';
import { get } from 'lodash';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectArrayInput,
  TextInput,
  required,
  useGetIdentity,
  usePermissions,
} from 'react-admin';
import { measurementDeviceTypes } from '../constants/measurementDeviceTypes';
import { parseGatewayId } from '../utils/parseGatewayId';
import { addDashToFourChars } from './DeviceExternalIdField';
import { DeviceLocationInput } from './DeviceLocationInput';

const tenoviGatewayIdValidator = (value) => {
  if (!value) {
    return 'Gateway ID is required';
  }
  const formattedStr = value.replace(/-/g, '');
  // Check if the string matches either of the two formats
  const regex = /^([a-zA-Z0-9]{4}-){2}[a-zA-Z0-9]{4}$|^[a-zA-Z0-9]{12}$/;
  if (regex.test(formattedStr)) {
    return undefined;
  } else {
    return 'Gateway ID must be 12 characters or 4 characters separated by dashes';
  }
};

export const DeviceCreateTenovi = (props) => {
  const { variant = 'text' } = props;

  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();

  const transform = (data) => {
    let dataOut = {
      ...data,
      external_id: parseGatewayId(
        data.external_id,
        data.device_manufacturer,
        'tenovi_gateway',
      ),
      status: !data.device_received ? 'pending' : null,
      device_manufacturer: 'tenovi',
    };
    if (get(permissions, 'is_internal') === false) {
      dataOut['tenant_id'] = identity && identity.tenant_id;
    }
    return dataOut;
  };

  if (!get(permissions, 'devices.create')) {
    return null;
  }

  return (
    <CreateInDialogButton
      title="Add Tenovi Device"
      transform={transform}
      variant={variant}
      label="Tenovi Device"
      saveAndAddAnother
      saveAndAddAnotherClearFields={['external_id']}
    >
      <div className="flex flex-col space-y-1 w-fit">
        <div className="text-gray-600 mb-4">
          This form is specifically for adding Tenovi devices and gateways.
        </div>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                <SelectArrayInput
                  source="device_types"
                  choices={measurementDeviceTypes}
                  optionText={(record) => inflection.humanize(record.id)}
                  helperText="Select the type of device. Keep empty to just add a gateway."
                />
                <RadioButtonGroupInput
                  source="gateway_add_type"
                  defaultValue="new"
                  choices={[
                    { id: 'new', name: 'New Gateway' },
                    { id: 'existing', name: 'Existing Gateway' },
                  ]}
                  helperText={null}
                />
                {formData.gateway_add_type === 'existing' ? (
                  <ReferenceInput
                    source="external_id_device_id"
                    reference="devices"
                    filter={{
                      'device_type[eq]': 'tenovi_gateway',
                    }}
                    sort={{ field: 'added_on', order: 'DESC' }}
                  >
                    <AutocompleteInput
                      optionText={(record) =>
                        addDashToFourChars(record?.external_id)
                      }
                      label="Existing Gateway ID"
                      helperText="Select which gateway this device belongs to. Please be sure to add the gateway first."
                      validate={required()}
                      filterToQuery={(searchText) => ({
                        'external_id[like]': `%${searchText}%`,
                      })}
                    />
                  </ReferenceInput>
                ) : (
                  <TextInput
                    label="New Gateway ID"
                    source="external_id"
                    parse={(v) => (v === '' ? null : v)}
                    validate={tenoviGatewayIdValidator}
                    helperText={null}
                  />
                )}
              </>
            );
          }}
        </FormDataConsumer>
        <TextInput
          source="device_model"
          helperText={false}
          label="Model number"
        />
        <div className="border-t border-gray-200 my-4" />
        {get(permissions, 'is_internal') === true ? (
          <ReferenceInput source="tenant_id" reference="providers">
            <AutocompleteInput
              label="Provider"
              optionText="name"
              helperText="Select which provider this device belongs to"
              filterToQuery={filterTenantName}
            />
          </ReferenceInput>
        ) : null}
        <DeviceLocationInput />
        <BooleanInput
          source="device_received"
          defaultValue={false}
          helperText="Has the device been received at the location?"
        />
      </div>
    </CreateInDialogButton>
  );
};
