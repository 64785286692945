import { TestCallButton } from '@/modules/voice-calls/components/TestCallButton';
import { VoiceCallsTable } from '@/modules/voice-calls/components/VoiceCallsTable';
import { get } from 'lodash';
import React from 'react';
import { ListBase, useGetIdentity, usePermissions } from 'react-admin';
import { useNavigate } from 'react-router-dom';

export const VoiceCallsPage = () => {
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const navigate = useNavigate();

  if (!get(permissions, 'voice_calls.list')) {
    navigate('/');
  }

  return (
    <ListBase
      resource="voice-calls"
      filter={{ 'caller_id[eq]': identity?.id }}
      sort={{ field: 'start_time', order: 'DESC' }}
      perPage={10}
    >
      <div className="container mx-auto py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Voice Calls</h1>
          <TestCallButton />
        </div>
        <VoiceCallsTable />
      </div>
    </ListBase>
  );
};
