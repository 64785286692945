import { Button } from '@/modules/ui/components/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { Pencil } from 'lucide-react';
import React from 'react';
import {
  DateInput,
  DeleteButton,
  SelectInput,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import { encounterStatus } from '../../constants/encounterStatus';
import { encounterType } from '../../constants/encounterType';
import { encounterClass } from '../../constants/encounterClass';

export function EncounterEditButton() {
  const record = useRecordContext();

  if (record.source !== 'vironix') {
    return (
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger asChild className="z-50">
            <span tabIndex={0}>
              <Button
                disabled
                size="sm"
                className="h-8 pointer-events-none"
                variant="link"
              >
                <Pencil className="mr-2 h-4 w-4 fill-primary" />
              </Button>
            </span>
          </TooltipTrigger>
          <TooltipContent>
            <p>Can only edit encounters sourced from Vironix</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <EditInDialogButton
      title={`Edit Encounter (#${record.id})`}
      resource={`patients/${record.patient_id}/encounters`}
      deleteButton={
        <DeleteButton redirect={false} confirmTitle="Delete encounter?" />
      }
    >
      <div className="grid grid-col-4 gap-0">
        <DateInput
          source="start_date"
          defaultValue={new Date().toISOString().split('T', 1)[0]}
          parse={(v) => new Date(v).toISOString().split('T', 1)[0]}
          validate={required()}
          helperText={false}
        />
        <DateInput
          source="end_date"
          helperText={false}
          parse={(v) => new Date(v).toISOString().split('T', 1)[0]}
        />
        <SelectInput
          source="status"
          choices={encounterStatus}
          helperText={false}
          validate={required()}
        />
        <SelectInput
          source="encounter_type"
          choices={encounterType}
          helperText={false}
          validate={required()}
        />
        <SelectInput
          source="encounter_class"
          choices={encounterClass}
          helperText={false}
          validate={required()}
        />
        <TextInput source="department" helperText={false} />
        <TextInput
          source="notes"
          helperText={false}
          multiline
          minRows={2}
          validate={required()}
        />
      </div>
    </EditInDialogButton>
  );
}
