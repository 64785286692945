import * as React from 'react';
import { useGetOne } from 'react-admin';
import { Alert, AlertTitle } from '../../ui/components/alert';
import { useParams } from 'react-router-dom';
import inflection from 'inflection';
import { useAttendance } from '@/modules/attendance/hooks/UserCheckedIn';

export const CanBeConsentedReasons: React.FC = () => {
  // Hooks
  const { id } = useParams();
  const { data } = useGetOne('patients', {
    id: `${id}/can-be-consented`,
  });
  const { isCheckedIn, attendanceEnforced } = useAttendance();

  // Derived state
  const hasMissingItems = data?.missing_items?.length > 0;

  // Render missing items alert
  const renderMissingItemsAlert = () => {
    if (!hasMissingItems) return null;

    return (
      <Alert variant="warning" className="my-2">
        <AlertTitle>Cannot mark as consented</AlertTitle>
        <div className="text-left mt-2">
          <p className="mb-2">
            The following items need to be completed first:
          </p>
          <ul className="list-disc pl-6">
            {data.missing_items.map((item, index) => (
              <li key={index} className="text-sm text-gray-600">
                {inflection.humanize(item)}
              </li>
            ))}
          </ul>
        </div>
      </Alert>
    );
  };

  // Render check-in alert
  const renderCheckInAlert = () => {
    if (!attendanceEnforced || isCheckedIn) return null;

    return (
      <Alert variant="warning" className="my-1">
        <AlertTitle>Check-in Required</AlertTitle>
        <div className="text-left mt-2">
          <p>You must check in before consenting patients.</p>
        </div>
      </Alert>
    );
  };

  return (
    <>
      {renderMissingItemsAlert()}
      {renderCheckInAlert()}
    </>
  );
};
