import { Button } from '@/modules/ui/components/button';
import React from 'react';
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useDataProvider,
} from 'react-admin';
import { get } from 'lodash';

interface SendEmailVerificationButtonProps {
  resource?: string;
  label?: string;
  source?: string;
}

export const SendEmailVerificationButton = ({
  resource = 'users',
  label = 'Send Verification Email',
  source = 'is_email_verified',
}: SendEmailVerificationButtonProps) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const isVerified = get(record, source);

  const handleSendVerification = async () => {
    try {
      await dataProvider.post(
        `${resource}/${record.id}/send-email-verification`,
        {
          data: {},
        },
      );
      refresh();
      notify('Verification email sent successfully', { type: 'success' });
    } catch (error: any) {
      notify(`Error: ${error?.message}`, { type: 'error' });
    }
  };

  if (!record || isVerified) {
    return null;
  }

  return (
    <Button
      onClick={handleSendVerification}
      variant="outline"
      size="sm"
      className="whitespace-nowrap"
    >
      {label}
    </Button>
  );
};
