import { ChevronsRight } from 'lucide-react';
import { Button } from '@/modules/ui/components/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/modules/ui/components/tooltip';
import { cn } from '@/modules/ui/utils/cn';
import React from 'react';
import { useSidebar } from '@/modules/ui/components/sidebar';

export function SidebarButton() {
  const { open, setOpen } = useSidebar();

  const onClick = () => {
    setOpen(!open);
  };

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            onClick={onClick}
            className={cn('h-6 w-6 p-0 hover:bg-accent')}
          >
            <ChevronsRight
              className={cn(
                'h-5 w-5  transition-transform duration-200',
                open && 'rotate-180',
              )}
            />
            <span className="sr-only">
              {open ? 'Collapse sidebar' : 'Expand sidebar'}
            </span>
          </Button>
        </TooltipTrigger>
        <TooltipContent side="right">
          <p>{open ? 'Collapse sidebar' : 'Expand sidebar'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
