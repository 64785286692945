import { get } from 'lodash';
import {
  Form,
  required,
  useAuthProvider,
  useNotificationContext,
  useNotify,
  useSafeSetState,
  useTranslate,
} from 'ra-core';
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoaderIcon } from 'lucide-react';
import { Button } from '@/modules/ui/components/button';
import { TextInput } from '@/modules/ui/inputs/text-input';

import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import {
  passwordValidation,
  passwordsMatch,
} from '../utils/passwordValidation';

const apiUrl = window.config.apiUrl;

export const SetPasswordForm = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { resetNotifications } = useNotificationContext();

  useEffect(() => {
    const emailVerificationToken = searchParams.get('verifyEmailCode');

    if (emailVerificationToken) {
      fetch(apiUrl + '/auth/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: emailVerificationToken,
        }),
      });
    }
  }, [searchParams]);

  const submit = (values) => {
    setLoading(true);
    authProvider
      .setPassword({ ...values, code: searchParams.get('resetCode') })
      .then((decodedToken) => {
        setLoading(false);
        resetNotifications();
        navigate('/');
      })
      .catch((error) => {
        setLoading(false);

        if (error?.message === 'Role is patient.') {
          navigate('/login?page=reset-success-patient');
          return;
        }

        if (error?.status === 422) {
          notify(
            get(
              error,
              'body.detail[0].msg',
              'Password does not meet requirements',
            ),
            {
              type: 'warning',
            },
          );
        } else {
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
                ? 'ra.auth.sign_in_error'
                : error.message,
            {
              type: 'warning',
              messageArgs: {
                _:
                  typeof error === 'string'
                    ? error
                    : error && error.message
                      ? error.message
                      : undefined,
              },
            },
          );
        }
      });
  };

  return (
    <>
      <CardHeader>
        <CardTitle className="text-2xl">Set a new password</CardTitle>
        <CardDescription>Set a new password for your account</CardDescription>
      </CardHeader>
      <CardContent>
        <Form onSubmit={submit} mode="onChange" noValidate>
          <div className="flex flex-col gap-1">
            <TextInput
              source="new_password"
              label={translate('ra.auth.password')}
              type="password"
              autoComplete="current-password"
              validate={passwordValidation}
              helperText={false}
            />
            <TextInput
              source="new_password_2"
              label="Confirm password"
              type="password"
              autoComplete="current-password"
              validate={[required(), passwordsMatch()]}
              helperText={false}
            />

            <Button
              variant="default"
              type="submit"
              color="primary"
              disabled={loading}
              className="w-full mt-4"
            >
              {loading ? <LoaderIcon className="animate-spin" /> : 'Save'}
            </Button>
          </div>
        </Form>
      </CardContent>
    </>
  );
};
