import { useState, useEffect, useCallback } from 'react';
import inMemoryJWT from '@/utils/inMemoryJWT';

interface TelnyxTokenResponse {
  token: string;
  expires_at: string;
  phone_number: string;
}
const apiUrl = window.config.apiUrl;

// Add these outside the hook to share across instances
let globalToken: string | null = null;
let globalExpiresAt: Date | null = null;
let globalPhoneNumber: string | null = null;
let globalFetchPromise: Promise<void> | null = null;
let globalError: boolean = false;

export const useTelnyxToken = () => {
  const [token, setToken] = useState<string | null>(globalToken);
  const [expiresAt, setExpiresAt] = useState<Date | null>(globalExpiresAt);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(globalPhoneNumber);
  const [error, setError] = useState<boolean>(globalError);

  const fetchToken = useCallback(async () => {
    const now = new Date();
    if (globalExpiresAt && (globalExpiresAt.getTime() - now.getTime() > 5 * 60 * 1000)) {
      return; // Skip if token is still valid for more than 5 minutes
    }

    // Don't retry if we got a 400 error previously
    if (globalError && globalFetchPromise === null) {
      return;
    }

    // Prevent multiple simultaneous fetches
    if (globalFetchPromise) {
      await globalFetchPromise;
      return;
    }

    globalFetchPromise = (async () => {
      try {
        const jwtToken = await inMemoryJWT.getToken('telnyx_credential');
        const response = await fetch(`${apiUrl}/voice-calls/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (response.status === 400) {
          // For 400 status, set error and don't retry
          throw new Error('Bad Request - will not retry');
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: TelnyxTokenResponse = await response.json();

        globalToken = data.token;
        globalExpiresAt = new Date(data.expires_at);
        globalPhoneNumber = data.phone_number;
        globalError = false;
        setToken(globalToken);
        setExpiresAt(globalExpiresAt);
        setPhoneNumber(globalPhoneNumber);
        setError(false);
      } catch (error) {
        console.error('Failed to fetch Telnyx token:', error);
        globalError = true;
        setError(true);
        setToken(null);
        setExpiresAt(null);
        setPhoneNumber(null);
      } finally {
        globalFetchPromise = null;
      }
    })();

    await globalFetchPromise;
  }, []);

  useEffect(() => {
    fetchToken();
    const refreshInterval = setInterval(fetchToken, 60000);
    return () => clearInterval(refreshInterval);
  }, [fetchToken]);

  return { token, expiresAt, phoneNumber, error };
};
