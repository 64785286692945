import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { Badge } from '@/modules/ui/components/badge';
import { get } from 'lodash';

export const columns: ColumnDef<any>[] = [
  {
    id: 'user.last_name',
    accessorKey: 'user.full_name',
    header: 'Patient Name',
    cell: ({ row }) => {
      const fullName = get(row.original, 'user.full_name');
      return <span>{fullName}</span>;
    },
  },
  {
    id: 'user.enrolled_on',
    accessorKey: 'user.enrolled_on',
    header: 'Enrolled On',
    cell: ({ row }) => {
      const enrolledAt = get(row.original, 'user.enrolled_on');
      if (!enrolledAt) return '-';
      const date = DateTime.fromISO(enrolledAt, {
        zone: 'utc',
      }).setZone('local');
      return date.toLocaleString(DateTime.DATE_MED);
    },
  },
  {
    accessorKey: 'is_valid_enrollment',
    header: 'Enrollment Status',
    cell: ({ row }) => {
      const isValidEnrollment = get(row.original, 'is_valid_enrollment');
      return isValidEnrollment ? (
        <Badge
          variant="default"
          className="bg-green-100 text-green-700 border-green-200 hover:bg-green-200 transition-colors"
        >
          Valid
        </Badge>
      ) : (
        <Badge
          variant="default"
          className="bg-red-100 text-red-700 border-red-200 hover:bg-red-200 transition-colors"
        >
          Not met
        </Badge>
      );
    },
  },
];
