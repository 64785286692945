import { Badge } from '@/modules/ui/components/badge';
import { Card } from '@/modules/ui/components/card';
import { ColumnDef } from '@tanstack/react-table';
import inflection from 'inflection';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { RecordContextProvider, useListContext } from 'ra-core';
import React from 'react';
import { DataTable } from '../../data-table/components/data-table';
import { CallInfoDialog } from './CallInfoDialog';

const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'recipient_phone_number',
    header: 'Recipient Phone Number',
    cell: ({ row }) => {
      const phoneNumber = get(row.original, 'recipient_phone_number');
      return <span>{phoneNumber || '-'}</span>;
    },
  },
  {
    accessorKey: 'caller_phone_number',
    header: 'Caller Phone Number',
    cell: ({ row }) => {
      const phoneNumber = get(row.original, 'caller_phone_number');
      return <span>{phoneNumber || '-'}</span>;
    },
  },
  {
    accessorKey: 'start_time',
    header: 'Start Time',
    cell: ({ row }) => {
      const startTime = get(row.original, 'start_time');
      if (!startTime) return '-';
      const date = DateTime.fromISO(startTime, {
        zone: 'utc',
      }).setZone('local');
      return date.toLocaleString(DateTime.DATETIME_MED);
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const status = get(row.original, 'status');

      return status ? (
        <Badge variant="outline" className="w-max">
          {inflection.humanize(status)}
        </Badge>
      ) : (
        <span>-</span>
      );
    },
  },
  {
    accessorKey: 'duration',
    header: 'Duration',
    cell: ({ row }) => {
      const durationSeconds = get(row.original, 'duration');
      if (durationSeconds == null) return '-';
      const duration = DateTime.fromSeconds(durationSeconds).toFormat('mm:ss');
      return <span>{duration}</span>;
    },
  },
  {
    accessorKey: 'hangup_source',
    header: 'Hangup Source',
    cell: ({ row }) => {
      const hangupSource = get(row.original, 'hangup_source');
      return hangupSource ? (
        <Badge variant="outline">{inflection.humanize(hangupSource)}</Badge>
      ) : (
        <span>-</span>
      );
    },
  },
  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) => {
      return (
        <RecordContextProvider value={row.original}>
          <CallInfoDialog
            callId={row.original.id}
            buttonProps={{
              variant: 'default',
              text: 'View',
            }}
          />
        </RecordContextProvider>
      );
    },
  },
];

export const VoiceCallsTable = () => {
  const { data, isLoading } = useListContext();
  return (
    <Card className="p-4">
      <DataTable columns={columns} data={data || []} loading={isLoading} />
    </Card>
  );
};
