import { AttendanceListTable } from '@/modules/attendance/components/AttendanceListTable';
import AppTitle from '@/modules/layout/components/app-title';
import React, { FC } from 'react';
import { ListBase } from 'react-admin';

export const AttendanceList: FC = () => {
  return (
    <>
      <AppTitle title="Attendance" />
      <ListBase
        resource="attendances"
        sort={{ field: 'check_in_time', order: 'DESC' }}
        perPage={10}
      >
        <AttendanceListTable />
      </ListBase>
    </>
  );
};
