import { DateTime } from 'luxon';

export function checkDeviceMeasurements(hasDevice: boolean , lastDeviceMeasurement: string | null): {
  hasWarning: boolean;
  warningType: 'no-measurements' | 'stale-measurements' | null;
  businessDays?: number;
} {
  if (!hasDevice) {
    return {
      hasWarning: false,
      warningType: null,
    };
  }

  if (!lastDeviceMeasurement) {
    return {
      hasWarning: true,
      warningType: 'no-measurements',
    };
  }
  const lastMeasurementDate = DateTime.fromISO(lastDeviceMeasurement);
  const today = DateTime.now();

  // Calculate business days by iterating through each day and excluding weekends
  let businessDays = 0;
  let currentDate = lastMeasurementDate;

  while (currentDate < today) {
    // Check if current day is not a weekend (1-5 are Monday-Friday)
    if (currentDate.weekday >= 1 && currentDate.weekday <= 5) {
      businessDays++;
    }
    currentDate = currentDate.plus({ days: 1 });
  }

  if (businessDays > 7) {
    return {
      hasWarning: true,
      warningType: 'stale-measurements',
      businessDays,
    };
  }

  return {
    hasWarning: false,
    warningType: null,
    businessDays,
  };
}
