import inflection from 'inflection';
import { get } from 'lodash';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  useGetIdentity,
  usePermissions,
} from 'react-admin';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { measurementDeviceTypes } from '../constants/measurementDeviceTypes';
import { deviceManufacturers } from '../constants/deviceManufacturers';
import { filterTenantName } from '@/utils/filterTenantName';
import { filterPatientName } from '@/utils/filterPatientName';
import { DeviceLocationInput } from './DeviceLocationInput';
import { Separator } from '@/modules/ui/components/separator';

export const DeviceCreateButton = (props) => {
  const { variant = 'text' } = props;

  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();

  const transform = (data) => {
    let deviceStatus = 'inactive';
    if (data.device_received) {
      deviceStatus = 'active';
    }
    if (data.patient_id) {
      deviceStatus = 'active';
    }
    let dataOut = {
      ...data,
      status: deviceStatus,
    };
    if (get(permissions, 'is_internal') === false) {
      dataOut['tenant_id'] = identity && identity.tenant_id;
    }
    return dataOut;
  };

  if (
    !get(permissions, 'devices.create') ||
    get(permissions, 'role') === 'internal_clinician'
  ) {
    return null;
  }

  return (
    <CreateInDialogButton
      title="Add device"
      transform={transform}
      variant={variant}
      label="Device"
      saveAndAddAnother
      saveAndAddAnotherClearFields={['external_id']}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row gap-4">
          <SelectInput
            source="device_manufacturer"
            choices={deviceManufacturers.filter((m) => m.id !== 'tenovi')}
            fullWidth
            helperText="Select the manufacturer of the device"
            validate={required()}
          />
        </div>

        <SelectInput
          source="device_type"
          choices={measurementDeviceTypes}
          optionText={(record) => inflection.humanize(record.id)}
          validate={required()}
          helperText={false}
        />
        <TextInput
          label="IMEI"
          source="external_id"
          parse={(v) => (v === '' ? null : v)}
          helperText={false}
        />
        <TextInput
          source="device_model"
          helperText={false}
          label="Model number"
        />

        <Separator />
        {get(permissions, 'is_internal') === true ? (
          <ReferenceInput source="tenant_id" reference="providers">
            <AutocompleteInput
              label="Provider"
              optionText="name"
              helperText="Select which provider this device belongs to"
              filterToQuery={filterTenantName}
            />
          </ReferenceInput>
        ) : null}
        <DeviceLocationInput />
        <BooleanInput
          source="device_received"
          defaultValue={false}
          helperText="Has the device been received at the location?"
        />
        <BooleanInput
          source="is_shared_device"
          defaultValue={false}
          helperText="Do you intend to use this device for multiple patients? (ie. nursing facility)"
        />

        <FormDataConsumer>
          {({ formData }) => {
            if (!formData.is_shared_device) {
              return (
                <ReferenceInput
                  source="patient_id"
                  reference="patients"
                  sort={{ field: 'full_name', order: 'ASC' }}
                  filter={{
                    'tenant_id[eq]': formData.tenant_id || identity?.tenant_id,
                    'patient_status[in]': ['active', 'consented'],
                  }}
                >
                  <AutocompleteInput
                    filterToQuery={filterPatientName}
                    label="Patient"
                    optionText="user.full_name"
                    parse={(v) => (v === '' ? null : v)}
                    disabled={!formData.tenant_id || !formData.external_id}
                    helperText={
                      !formData.tenant_id || !formData.external_id
                        ? 'Please pick a provider and provide an external ID.'
                        : 'Select which patient this device is assigned to'
                    }
                  />
                </ReferenceInput>
              );
            } else {
              return null;
            }
          }}
        </FormDataConsumer>
      </div>
    </CreateInDialogButton>
  );
};
