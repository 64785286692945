import { MyEnrolledPatientsTable } from '@/modules/account/components/MyEnrolledPatientsTable';
import { get } from 'lodash';
import React from 'react';
import { ListBase, useGetIdentity, usePermissions } from 'react-admin';
import { useNavigate } from 'react-router-dom';

export const EnrolledPatientsPage = () => {
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const navigate = useNavigate();

  if (!get(permissions, 'patients.list')) {
    navigate('/');
  }

  return (
    <ListBase
      resource="patients"
      filter={{ 'enrolled_by_id[eq]': identity?.id }}
      sort={{ field: 'user.enrolled_on', order: 'DESC' }}
      perPage={10}
    >
      <div className="container mx-auto py-6">
        <h1 className="text-2xl font-bold mb-6">My Enrolled Patients</h1>
        <MyEnrolledPatientsTable />
      </div>
    </ListBase>
  );
};
