import React from 'react';
import {
  useDelete,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'ra-core';
import { Button } from '@/modules/ui/components/button';
import { get } from 'lodash';

export const RevokePatientDataRequestButton = () => {
  const [deleteOne, { isLoading }] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const { permissions } = usePermissions();

  if (!get(permissions, 'data_export.delete')) return null;

  if (record?.status === 'completed') return null;

  const handleRevoke = () => {
    deleteOne(
      'data-exports',
      { id: record?.id },
      {
        onSuccess: () => {
          notify('Data export request revoked successfully');
          refresh();
        },
      },
    );
  };

  return (
    <Button
      size="sm"
      variant="outline"
      onClick={handleRevoke}
      disabled={isLoading}
    >
      {isLoading ? 'Revoking...' : 'Revoke'}
    </Button>
  );
};
