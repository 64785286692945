import inflection from 'inflection';
import * as React from 'react';
import { useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Alert, AlertTitle } from '../../ui/components/alert';

export const CanBeActiveReasons: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetOne('patients', {
    id: `${id}/consented-to-active-progress`,
  });

  if (!data?.missing_items?.length) return null;

  return (
    <div className="flex flex-col gap-2 mb-4">
      {data.is_recently_consented_and_claimable && (
        <Alert variant="default" className="mb-0 bg-green-100 border-green-500">
          <AlertTitle>Enrollment Claimable</AlertTitle>
          <p className="text-sm">
            This patient can be claimed by any clinician who completes these
            items.
          </p>
        </Alert>
      )}
      <Alert variant="warning">
        <AlertTitle>Progress to active status</AlertTitle>
        <div className="text-left mt-2">
          <p className="mb-2 text-sm">
            Once the following items are completed, the patient will be set to
            active automatically.
          </p>
          <ul className="list-disc pl-6">
            {data.missing_items.map((item, index) => (
              <li key={index} className="text-sm text-gray-600">
                {inflection.humanize(item)}
              </li>
            ))}
          </ul>
        </div>
      </Alert>
    </div>
  );
};
