import { Button } from '@/modules/ui/components/button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/modules/ui/components/alert-dialog';
import { PlusIcon } from 'lucide-react';
import React, { useState } from 'react';

export const MonitoringActivityCreateDepreciatedButton = () => {
  const [open, setOpen] = useState(false);

  // Check if we should hide the button
  const hideDate = new Date('2025-02-01');
  const currentDate = new Date();

  if (currentDate >= hideDate) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setOpen(true)} size="sm" className="h-8">
        <PlusIcon className="h-4 w-4 mr-2" />
        Monitoring
      </Button>

      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Deprecated Feature</AlertDialogTitle>
            <AlertDialogDescription>
              This button is no longer supported. Please use the aside panel to
              create monitoring activities.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setOpen(false)}>
              Okay, I understand
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
