import React from 'react';
import { useDataProvider } from 'ra-core';
import { useQuery } from 'react-query';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/modules/ui/components/dialog';
import { Button } from '@/modules/ui/components/button';
import { Info } from 'lucide-react';
import { DateTimeProviderTZField } from '@/modules/ra-ui/components/DateTimeProviderTZField';
import { formatDurationAbbreviated } from '@/utils/formatDurationAbbreviated';
import { Separator } from '@/modules/ui/components/separator';
import { Skeleton } from '@/modules/ui/components/skeleton';
import CallRecordingPlayer from './CallRecordingPlayer';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { DirectionBadge } from './DirectionBadge';
import inflection from 'inflection';
import { Badge } from '@/modules/ui/components/badge';
import { VoiceCallCallerName, VoiceCallRecipientName } from './VoiceCallName';

interface CallInfoDialogProps {
  callId: string;
  buttonProps?: {
    variant?:
      | 'default'
      | 'destructive'
      | 'outline'
      | 'secondary'
      | 'ghost'
      | 'link';
    className?: string;
    text?: string;
  };
}

export function CallInfoDialog({
  callId,
  buttonProps = {
    variant: 'ghost',
    className: null,
    text: 'View Details',
  },
}: CallInfoDialogProps) {
  const [open, setOpen] = React.useState(false);
  const dataProvider = useDataProvider();

  const { data: call, isLoading } = useQuery(
    ['voice-call', callId],
    () =>
      dataProvider
        .getOne('voice-calls', { id: callId })
        .then(({ data }) => data),
    {
      enabled: open,
    },
  );

  const { data: recording, isLoading: isLoadingRecording } = useQuery(
    ['voice-call-recording', callId],
    () =>
      dataProvider
        .getOne('voice-calls', { id: `${callId}/recording` })
        .then(({ data }) => data),
    {
      enabled: open,
    },
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant={buttonProps.variant}
          size="sm"
          className={buttonProps.className}
        >
          <Info className="h-4 w-4 mr-2" />
          {buttonProps.text}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Call Details</DialogTitle>
          <DialogDescription>
            View detailed information about this call
          </DialogDescription>
        </DialogHeader>

        {isLoading ? (
          <div className="space-y-4">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-3/4" />
            <Skeleton className="h-4 w-1/2" />
          </div>
        ) : call ? (
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
            <div>
                <h4 className="font-medium text-gray-700">Caller</h4>
                <VoiceCallCallerName record={call} />
              </div>
              <div>
                <h4 className="font-medium text-gray-700">Recipient</h4>
                <VoiceCallRecipientName record={call} />
              </div>

              <div>
                <h4 className="font-medium text-gray-700">Call Time</h4>
                {call.tenant_id ? (
                  <DateTimeProviderTZField
                    source="start_time"
                    showTime
                    showTZ
                    tenant_id={call.tenant_id}
                  />
                ) : (
                  <LuxonDateField source="start_time" showTime showDate />
                )}
              </div>
              <div>
                <h4 className="font-medium text-gray-700">Duration</h4>
                <p>{formatDurationAbbreviated(call.duration)}</p>
              </div>
              <div>
                <h4 className="font-medium text-gray-700">Direction</h4>
                <DirectionBadge direction={call.direction} />
              </div>
              <div>
                <h4 className="font-medium text-gray-700">Hangup Source</h4>
                <Badge variant="outline">{inflection.humanize(call.hangup_source)}</Badge>
              </div>
            </div>

            <Separator />

            <div>
              <h4 className="font-medium text-gray-700 mb-2">Call Recording</h4>
              {isLoadingRecording ? (
                <div className="bg-gray-50 p-4 rounded-lg text-gray-500">
                  Loading recording...
                </div>
              ) : !recording ? (
                <div className="bg-gray-50 p-4 rounded-lg text-gray-500">
                  Recording not available
                </div>
              ) : recording?.recording_url ? (
                <CallRecordingPlayer
                  url={recording.recording_url}
                  isActive={open}
                />
              ) : null}
            </div>

            <Separator />

            <div>
              <h4 className="font-medium text-gray-700 mb-2">
                Call Transcript
              </h4>
              {isLoadingRecording ? (
                <div className="bg-gray-50 p-4 rounded-lg min-h-[100px] text-gray-500">
                  Loading transcription...
                </div>
              ) : !recording ? (
                <div className="bg-gray-50 p-4 rounded-lg min-h-[100px] text-gray-500">
                  Transcription not available
                </div>
              ) : recording.transcript ? (
                <div className="bg-gray-50 p-4 rounded-lg min-h-[100px] whitespace-pre-wrap">
                  {recording.transcript}
                </div>
              ) : (
                <div className="bg-gray-50 p-4 rounded-lg min-h-[100px] text-gray-500">
                  Transcription not available
                </div>
              )}
            </div>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
