import AppTitle from '@/modules/layout/components/app-title';
import { Box, Grid, Stack, Toolbar } from '@mui/material';
import React from 'react';
import {
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Title,
  useCreateController,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { CardSectionLayout } from '../../modules/testing/components/CardSectionLayout';

const testAccounts = [
  { id: 'copd@vironix.ai', name: 'copd@vironix.ai' },
  { id: 'copd_base@vironix.ai', name: 'copd_base@vironix.ai' },
  { id: 'asthma@vironix.ai', name: 'asthma@vironix.ai' },
  { id: 'asthma_base@vironix.ai', name: 'asthma_base@vironix.ai' },
  { id: 'chf_base@vironix.ai', name: 'chf_base@vironix.ai' },
  { id: 'chf@vironix.ai', name: 'chf@vironix.ai' },
];

export const Testing = (props) => {
  return (
    <Box sx={{ m: 4, marginBottom: 2 }}>
      <AppTitle title="Testing" />
      <Title title="Testing" />
      <Grid container spacing={1} direction="column">
        <Grid item>
          <AddDeviceObservationToPatient />
        </Grid>
        <Grid item>
          <AddDeviceToPatient />
        </Grid>
        <Grid item>
          <AddDeviceObservationToPatientUpdated />
        </Grid>
      </Grid>
    </Box>
  );
};

const DeviceObservationInput = (props) => {
  const deviceType = useWatch({ name: 'device_type' });

  return (
    <Stack>
      <SelectInput
        label="Device type"
        source="device_type"
        required
        choices={[
          { id: 'pulse_oximeter', name: 'Pulse Oximeter' },
          { id: 'blood_pressure', name: 'Blood Pressure' },
          { id: 'weight_scale', name: 'Weight scale' },
          { id: 'peak_flow_meter', name: 'Peak Flow Meter' },
          { id: 'glucometer', name: 'Glucometer' },
        ]}
      />
      {deviceType === 'pulse_oximeter' ? (
        <NumberInput required source="spo2" label="SpO2 (%)" />
      ) : null}
      {deviceType === 'pulse_oximeter' || deviceType === 'blood_pressure' ? (
        <NumberInput required source="pulse" label="Heart Rate (bpm)" />
      ) : null}
      {deviceType === 'blood_pressure' ? (
        <NumberInput required source="systolic" label="Systolic (mmHg)" />
      ) : null}
      {deviceType === 'blood_pressure' ? (
        <NumberInput required source="diastolic" label="Diastolic (mmHg)" />
      ) : null}
      {deviceType === 'weight_scale' ? (
        <NumberInput required source="weight" label="Weight" />
      ) : null}
      {deviceType === 'weight_scale' ? (
        <SelectInput
          required
          source="unit"
          label="Unit"
          choices={[
            { id: 'lbs', name: 'lbs' },
            { id: 'kg', name: 'kg' },
          ]}
        />
      ) : null}
      {deviceType === 'glucometer' ? (
        <NumberInput required source="blood_glucose" label="Blood Glucose" />
      ) : null}
      {deviceType === 'peak_flow_meter' ? (
        <NumberInput
          required
          source="peak_expiratory_flow_rate"
          label="Peak Flow"
        />
      ) : null}
    </Stack>
  );
};

const AddDeviceObservationToPatient = (props) => {
  const { save } = useCreateController({
    resource: 'testing/add-device-observation',
    redirect: false,
  });

  const SubmitToolbar = () => (
    <Toolbar>
      <SaveButton label="Add" />
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Add Device Measurement">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <p className="text-base font-normal">
          Adds a device measurement to the test patient account.
        </p>
        <p className="text-base font-normal">
          Use this to mock device measurements.
        </p>
        <SelectInput
          fullWidth
          required
          label="Patient account email"
          source="email"
          choices={testAccounts}
        />
        <DeviceObservationInput />
      </SimpleForm>
    </CardSectionLayout>
  );
};

const AddDeviceObservationToPatientUpdated = (props) => {
  const { save } = useCreateController({
    resource: 'testing/add-device-observation',
    redirect: false,
  });

  const SubmitToolbar = () => (
    <Toolbar>
      <SaveButton label="Add" />
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Add Device Measurement">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <p className="text-base font-normal">
          Adds a device measurement to the test patient account.
        </p>
        <p className="text-base font-normal">
          Use this to mock device measurements.
        </p>
        <NumberInput source="patient_id" />
        <DeviceObservationInput />
      </SimpleForm>
    </CardSectionLayout>
  );
};

const AddDeviceToPatient = (props) => {
  const { save } = useCreateController({
    resource: 'testing/add-device',
    redirect: false,
  });

  const SubmitToolbar = () => (
    <Toolbar>
      <SaveButton label="Add" />
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Add Device">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <p className="text-base font-normal">
          Adds a test device to a test patient account.
        </p>
        <SelectInput
          fullWidth
          required
          label="Patient account email"
          source="email"
          choices={testAccounts}
        />
        <SelectInput
          required
          label="Device type"
          source="device_type"
          choices={[
            { id: 'pulse_oximeter', name: 'Pulse Oximeter' },
            { id: 'blood_pressure', name: 'Blood Pressure' },
          ]}
        />
        <TextInput source="external_id" label="IMEI" required={false} />
      </SimpleForm>
    </CardSectionLayout>
  );
};
