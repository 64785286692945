import HistoryIcon from '@mui/icons-material/History';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
} from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

const scoreCategoryMapping = {
  //map 1-5
  1: { name: 'Low', color: '#a7f171' },
  2: { name: 'Low - Medium', color: '#fffc00' },
  3: { name: 'Medium', color: '#ffc300' },
  4: { name: 'Medium - High', color: '#ff6d00' },
  5: { name: 'High', color: '#ff1800' },
};
const scoreExacerbationMapping = {
  //map 1-5
  1: { name: 'Low', color: '#a7f171' },
  2: { name: 'Low - Medium', color: '#fffc00' },
  3: { name: 'Medium', color: '#ffc300' },
  4: { name: 'Medium - High', color: '#ff6d00' },
  5: { name: 'High', color: '#ff1800' },
};

export function ScoreCard(props) {
  const { value, text, lastUpdated, type, handleIndicatorClick } = props;

  const formatUpdateChip = () => {
    const dateToday = DateTime.local();
    const assessmentTime = DateTime.fromISO(lastUpdated, {
      zone: 'utc',
    }).setZone('local');

    const diff = dateToday.diff(assessmentTime, ['minutes', 'hours', 'days']);

    let text;

    if (diff.days >= 2) {
      text = `${diff.days.toFixed(0)} days ago`;
    } else if (diff.days >= 1 && diff.days < 2) {
      text = `${diff.days.toFixed(0)} day ago`;
    } else if (diff.hours < 24 && diff.hours >= 1) {
      text = `${diff.hours.toFixed(0)} hours ago`;
    } else if (diff.minutes < 60 && diff.minutes > 5) {
      text = `${diff.minutes.toFixed(0)} minutes ago`;
    } else if (diff.minutes <= 5) {
      text = 'Just now';
    }

    return <Chip label={text} sx={{ color: 'black' }} />;
  };

  const formatValue = () => {
    if (type === 'category' && value.value) {
      const category = scoreCategoryMapping[value.value];

      return (
        <Box
          fontWeight={600}
          sx={{
            border: '2px solid',
            borderColor: category.color,
            borderRadius: '5px',
            width: 'max-content',
          }}
          px={1}
          my={1}
        >
          <h6 className="text-center">{category.name}</h6>
        </Box>
      );
    } else if (type === 'exacerbation') {
      // value - float from 0.0 to 1.0
      const score = value.value * 100;
      const bin = Math.ceil(score / 20) || 1;
      const category = scoreExacerbationMapping[bin];

      return (
        <Box
          fontWeight={600}
          sx={{
            border: '2px solid',
            borderColor: category?.color,
            borderRadius: '5px',
            width: 'max-content',
          }}
          px={1}
          my={1}
        >
          <h6 className="text-center"> {Math.round(score)}</h6>
        </Box>
      );
    }
  };

  const formatDate = () => {
    if (lastUpdated) {
      const dateToday = DateTime.local().startOf('day');
      const assessmentTime = DateTime.fromISO(lastUpdated, {
        zone: 'utc',
      })
        .setZone('local')
        .startOf('day');

      const diff = dateToday.diff(assessmentTime, 'days').days;

      if (diff >= 1) {
        return DateTime.fromISO(lastUpdated, {
          zone: 'utc',
        })
          .setZone('local')
          .toLocaleString(DateTime.DATETIME_SHORT);
      } else {
        return (
          'Today, ' +
          DateTime.fromISO(lastUpdated, {
            zone: 'utc',
          })
            .setZone('local')
            .toLocaleString(DateTime.TIME_SIMPLE)
        );
      }
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: 'max-content',
      }}
    >
      <CardActionArea onClick={handleIndicatorClick}>
        <CardContent>
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="space-between"
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={0}
              >
                <Grid item style={{ marginBottom: '8px' }}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <div className="text-sm font-medium">
                        <Box fontWeight={600}>{text}</Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <HistoryIcon fontSize="small" />
                        </Grid>
                        <Grid item>
                          <p className="text-sm">{formatDate()}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>{formatValue()}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ minHeight: '100%' }}
                alignContent="center"
              >
                <Grid item>{formatUpdateChip()}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
