import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const apiUrl = window.config.apiUrl;

export const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const emailVerificationToken = searchParams.get('verifyEmailCode');

    if (!emailVerificationToken) {
      setError(
        'No verification code found. Please check your verification email and try again.',
      );
      return;
    }

    fetch(apiUrl + '/auth/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: emailVerificationToken,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setVerified(true);
        } else {
          throw new Error('Verification failed');
        }
      })
      .catch(() => {
        setError('Email verification failed. Please try again.');
      });
  }, [searchParams]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center p-8">
        {verified ? (
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-2xl font-semibold text-green-600">
              Email verified successfully!
            </h1>
            <p>You may now close this window.</p>
          </div>
        ) : error ? (
          <h1 className="text-2xl font-semibold text-red-600">{error}</h1>
        ) : (
          <h1 className="text-2xl font-semibold">Verifying your email...</h1>
        )}
      </div>
    </div>
  );
};
