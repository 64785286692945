import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardContent } from '@/modules/ui/components/card';
import { Button } from '@/modules/ui/components/button';
const apiUrl = window.config.apiUrl;

export const DownloadPage = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>(
    'loading',
  );
  const [error, setError] = useState('');
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  useEffect(() => {
    const downloadToken = searchParams.get('token');

    if (!downloadToken) {
      setStatus('error');
      setError(
        'No download token found. Please check your download link and try again.',
      );
      return;
    }

    fetch(`${apiUrl}/data-exports/exchange-download-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: downloadToken,
      }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error('Download failed');
        const { signed_url } = await response.json();
        setDownloadUrl(signed_url);
        setStatus('success');
      })
      .catch(() => {
        setStatus('error');
        setError('Download failed. Please try again or contact support.');
      });
  }, [searchParams]);

  return (
    <div className="container mx-auto py-6 flex items-center justify-center min-h-screen">
      <Card className="w-full max-w-md">
        <CardContent className="p-6">
          {status === 'loading' && (
            <div className="text-center">
              <h1 className="text-2xl font-semibold">
                Preparing your download...
              </h1>
            </div>
          )}
          {status === 'success' && (
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-green-600 mb-2">
                Download Ready
              </h1>
              <p className="text-gray-600 mb-4">
                Click the button below to start your download.
              </p>
              <Button asChild>
                <a href={downloadUrl || '#'} download>
                  Download File
                </a>
              </Button>
            </div>
          )}
          {status === 'error' && (
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-red-600 mb-2">
                Download Error
              </h1>
              <p className="text-gray-600">{error}</p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default DownloadPage;
