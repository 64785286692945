import { Avatar, AvatarFallback } from '@/modules/ui/components/avatar';
import { Button } from '@/modules/ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/modules/ui/components/dropdown-menu';
import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu';
import inflection from 'inflection';
import { get } from 'lodash';
import { Lightbulb, Phone, Settings, Users } from 'lucide-react';
import React, { useCallback } from 'react';
import { useGetIdentity, useLogout, usePermissions } from 'react-admin';
import { Link } from 'react-router-dom';
import { useSidebar } from '@/modules/ui/components/sidebar';
import { getInitials } from '../../../utils/getInitials';
import { cn } from '@/modules/ui/utils/cn';

export function UserNav() {
  const { identity, isLoading } = useGetIdentity();
  const { open } = useSidebar();
  const { permissions } = usePermissions();

  const logout = useLogout();

  const handleClick = useCallback(
    () => logout(null, undefined, false),
    [logout],
  );

  if (isLoading) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={cn(
            'relative h-12 w-full justify-start px-4 space-x-4',
            !open && 'px-1',
          )}
        >
          <Avatar
            className={cn('h-7 w-7 m-0', !open && 'm-auto', !open && 'h-6 w-6')}
          >
            {/* <AvatarImage src="/avatars/01.png" alt="@shadcn" /> */}
            <AvatarFallback className={cn('bg-slate-300', !open && 'text-xs')}>
              {identity && getInitials(identity.fullName)}
            </AvatarFallback>
          </Avatar>
          {open && <p>{get(identity, 'fullName')}</p>}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuArrow />
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {get(identity, 'fullName')}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {inflection.humanize(get(identity, 'role', ''))}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {get(identity, 'email')}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuItem asChild>
          <Link key="account" to="/account">
            <Settings className="mr-2 h-4 w-4 " />
            Account
          </Link>
        </DropdownMenuItem>
        {get(identity, 'is_internal') && (
          <DropdownMenuItem asChild>
            <Link key="account" to="/account/enrolled-patients">
              <Users className="mr-2 h-4 w-4 " />
              My Enrolled Patients
            </Link>
          </DropdownMenuItem>
        )}
        {get(permissions, 'voice_calls.create') && (
          <DropdownMenuItem asChild>
            <Link key="account" to="/account/voice-calls">
              <Phone className="mr-2 h-4 w-4 " />
              My Voice Calls
            </Link>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem asChild>
          <a
            href="https://feedback.vironix.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Lightbulb className="mr-2 h-4 w-4" />
            Feedback
          </a>
        </DropdownMenuItem>
        {/* TODO: Uncomment this when we have a way to manage organization settings */}
        {/* {['admin'].includes(get(permissions, 'role')) && organization ? (
          <DropdownMenuItem asChild>
            <Link key="Organization" to="/organization">
              <SlidersHorizontal className="mr-2 h-4 w-4 " />
              Organization
            </Link>
          </DropdownMenuItem>
        ) : null} */}
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleClick}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
