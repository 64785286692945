import React, { ErrorInfo, Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import { OrganizationProvider } from '../../organizations/components/OrganizationProvider';
import { InactivityHandler } from './InactivityDialog';
import { ErrorBoundary } from 'react-error-boundary';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import { ErrorCapture } from './Error';
import TwoFactorEnforceDialog from '@/modules/account/components/TwoFactorEnforceDialog';
import { useRollbar } from '@rollbar/react';
import { useGetIdentity } from 'ra-core';
import { useRollbarPerson } from '@rollbar/react';
import { InProgressCall } from '@/modules/voice-calls/components/InProgressCall';
import { TelnyxRTCProvider } from '@telnyx/react-client';
import { useTelnyxToken } from '@/modules/voice-calls/hooks/useTelnyxToken';
import { SidebarProvider } from '@/modules/ui/components/sidebar';
import { AppSidebar } from './AppSidebar';
import { AttendanceProvider } from '@/modules/attendance/hooks/UserCheckedIn';

export function Layout({ children }) {
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>(null);
  const { identity } = useGetIdentity();
  const rollbar = useRollbar();
  const { token } = useTelnyxToken();

  useRollbarPerson(identity);

  const handleError = (_error: Error, info: ErrorInfo) => {
    setErrorInfo(info);
    rollbar.error(_error, info);
  };

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vironix</title>
        <link rel="canonical" href="https://rpm.vironix.ai" />
      </Helmet>
      <OrganizationProvider>
        <AttendanceProvider>
        <TelnyxRTCProvider credential={{ login_token: token || 'temp' }}>
          <SidebarProvider>
            <AppSidebar />
            <div className="flex flex-row h-screen w-full">
              <main className="w-full flex flex-row">
                <div
                  id="main-content"
                  className="border-l px-4 w-full overflow-x-auto"
                >
                  <div>
                    <div id="vironix-app-title"></div>
                    <InactivityHandler />
                    <TwoFactorEnforceDialog />
                    <ErrorBoundary
                      onError={handleError}
                      fallbackRender={({ error, resetErrorBoundary }) => (
                        <ErrorCapture
                          error={error}
                          resetErrorBoundary={resetErrorBoundary}
                          errorInfo={errorInfo}
                        />
                      )}
                    >
                      <Suspense
                        fallback={
                          <div>
                            <LoadingSpinner />
                          </div>
                        }
                      >
                        {children}
                      </Suspense>
                      <InProgressCall />
                    </ErrorBoundary>
                  </div>
                </div>
              </main>
            </div>
          </SidebarProvider>
        </TelnyxRTCProvider>
        </AttendanceProvider>
      </OrganizationProvider>
    </div>
  );
}
