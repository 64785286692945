import React from 'react';
import { Button } from '../ui/components/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/components/dialog';
import { useSidebar } from '../ui/components/sidebar';
import { Bell } from 'lucide-react';

export const UpdateDialog = ({ state, message, onSubmit, dialogTrigger }) => {
  const { open: sidebarOpen } = useSidebar();

  return (
    <Dialog open={state} onOpenChange={dialogTrigger}>
      <div
        className="cursor-pointer transition-all duration-800 ease-in-out"
        onClick={dialogTrigger}
      >
        {sidebarOpen ? (
          <span className="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500 transition-all duration-300 ease-in-out">
            <span className="size-1.5 inline-block rounded-full bg-blue-800 dark:bg-blue-500"></span>
            Update available
          </span>
        ) : (
          <span className="inline-flex items-center justify-center size-8 rounded-full bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500 transition-all duration-300 ease-in-out">
            <Bell className="size-4" />
          </span>
        )}
      </div>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Version Available </DialogTitle>
          <DialogDescription>{message}</DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button variant={'outline'} type="button" onClick={dialogTrigger}>
            Skip
          </Button>
          <Button type="button" onClick={onSubmit}>
            Update
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
