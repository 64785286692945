import React, { useEffect } from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';

export const DeviceLocationInput = () => {
  const tenantId = useWatch({ name: 'tenant_id' });
  const patientId = useWatch({ name: 'patient_id' });
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('location_id', null);
  }, [tenantId, patientId, setValue]);

  return (
    <ReferenceInput
      source="location_id"
      reference="locations"
      filter={{
        'is_active[eq]': true,
        'tenant_id[eq]': tenantId ? tenantId : null,
      }}
    >
      <AutocompleteInput
        label="Location"
        optionText="name"
        parse={(v) => (v === '' ? null : v)}
        helperText={
          !!patientId
            ? "Can't set location on devices assigned to a patient"
            : false
        }
        disabled={!!patientId}
      />
    </ReferenceInput>
  );
};
