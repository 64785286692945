import React, { useContext, useEffect, useState } from 'react';
import { useGetIdentity } from 'react-admin';
import { Phone } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/modules/ui/components/dialog';
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from '@/modules/ui/components/select';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TelnyxRTCContext } from '@telnyx/react-client';
import {
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { FormLabel } from '@mui/material';
import { useTelnyxToken } from '../hooks/useTelnyxToken';
import { Button } from '@/modules/ui/components/button';
import { PhoneNumberInput } from '@/modules/ra-ui/components/PhoneNumberInput';

function phone(schema: z.ZodString) {
  return schema
    .refine(
      (value) => isValidPhoneNumber(value),
      'Please specify a valid phone number (include the international prefix).',
    )
    .transform((value) => parsePhoneNumberFromString(value).number.toString());
}

const testCallFormSchema = z.object({
  phoneNumber: phone(z.string().min(1, 'Phone number is required')),
  inputDevice: z.string().min(1, 'Please select a microphone'),
});

type TestCallFormData = z.infer<typeof testCallFormSchema>;

export function TestCallButton() {
  const [open, setOpen] = React.useState(false);
  const { identity } = useGetIdentity();
  const client = useContext(TelnyxRTCContext);
  const { error, phoneNumber } = useTelnyxToken();
  const [audioDevices, setAudioDevices] = useState({ input: [], output: [] });
  const [isCallActive, setIsCallActive] = useState(false);

  const form = useForm<TestCallFormData>({
    resolver: zodResolver(testCallFormSchema),
    defaultValues: {
      phoneNumber: '',
      inputDevice: '',
    },
  });

  useEffect(() => {
    if (!open) return;
    const getAudioDevices = async () => {
      const inputDevices = await client.getAudioInDevices();
      const outputDevices = await client.getAudioOutDevices();
      setAudioDevices({ input: inputDevices, output: outputDevices });

      const activeInputDevice = inputDevices.find(
        (device) => device.deviceId === client.micId,
      );
      if (activeInputDevice) {
        form.setValue('inputDevice', activeInputDevice.deviceId);
      } else if (inputDevices.length > 0) {
        form.setValue('inputDevice', inputDevices[0].deviceId);
      }
    };

    getAudioDevices();
  }, [client, form, open]);

  useEffect(() => {
    const handleNotification = (notification) => {
      if (notification.type === 'callUpdate') {
        const call = notification.call;

        if (['hangup', 'destroy', 'purge'].includes(call.state)) {
          setIsCallActive(false);
        } else {
          setIsCallActive(true);
        }
      }
    };

    client.on('telnyx.notification', handleNotification);

    return () => {
      client.off('telnyx.notification', handleNotification);
    };
  }, [client]);

  const handleCall = async (data: TestCallFormData) => {
    await client.newCall({
      destinationNumber: data.phoneNumber,
      callerName: phoneNumber,
      callerNumber: phoneNumber,
      audio: true,
      video: false,
      id: `test_call;caller_id=${identity.id};${new Date().getTime()}`,
    });

    setOpen(false);
  };

  const handleSelectDevice = async (deviceId: string) => {
    const device = audioDevices.input.find((d) => d.deviceId === deviceId);
    if (!device) return;
    await client.setAudioSettings({
      micId: device.deviceId,
      micLabel: device.label,
    });
    form.setValue('inputDevice', deviceId);
  };

  if (error) return null;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          className="flex items-center"
          onClick={() => setOpen(true)}
          disabled={isCallActive}
        >
          <Phone className="mr-2 h-4 w-4" />
          Test Call
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Make Test Call</DialogTitle>
          <DialogDescription>
            Enter a phone number to make a test call.
          </DialogDescription>
        </DialogHeader>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleCall)} className="space-y-4">
            <div className="space-y-2">
              <FormLabel>Phone Number:</FormLabel>
              <PhoneNumberInput
                source="phoneNumber"
                placeholder="Enter phone number"
                label="Phone Number"
              />
              {form.formState.errors.phoneNumber && (
                <p className="text-red-500 text-sm mt-1">
                  {form.formState.errors.phoneNumber.message}
                </p>
              )}
            </div>

            <div>
              <FormLabel>Microphone:</FormLabel>
              <Select
                onValueChange={handleSelectDevice}
                value={form.watch('inputDevice')}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent>
                  {audioDevices.input.map((device) => (
                    <SelectItem key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {form.formState.errors.inputDevice && (
                <p className="text-red-500 text-sm mt-1">
                  {form.formState.errors.inputDevice.message}
                </p>
              )}
            </div>

            <Button type="submit" className="w-full">
              Start Test Call
            </Button>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
