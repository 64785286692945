import { CheckIcon, PlusCircle } from 'lucide-react';
import * as React from 'react';

import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/modules/ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { Separator } from '@/modules/ui/components/separator';
import { cn } from '@/modules/ui/utils/cn';
import { get, isEqual } from 'lodash';
import { useListContext } from 'react-admin';
import { FormProvider, useController, useForm } from 'react-hook-form';

interface DataTableFilterProps {
  column?: string;
  title?: string;
  options: {
    label: string;
    value: string | boolean;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
}

// replace `[` `]`with _ to avoid react-hook-form error
const sanitizeName = (name) => {
  return name.replace(/[[\].]/g, '_');
};

export function DataTableFilterSingle({
  column,
  title,
  options,
}: DataTableFilterProps) {
  const { filterValues, setFilters } = useListContext();
  const form = useForm<Object>({
    defaultValues: {
      [sanitizeName(column)]: get(filterValues, column),
    },
  });

  React.useEffect(() => {
    const newValue = get(filterValues, column);
    const previousValue = get(form.getValues(), sanitizeName(column));
    if (!isEqual(newValue, previousValue)) {
      form.reset({
        [sanitizeName(column)]: newValue,
      });
    }
    // The reference to the filterValues object is not updated when it changes,
    // so we must stringify it to compare it by value and also compare the reference.
    // This makes it work for both input values and filters applied directly through
    // the ListContext.setFilter (e.g. QuickFilter in the simple example)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues), filterValues, form]);

  React.useEffect(() => {
    const subscription = form.watch(async (values, { name }) => {
      const isFormValid = await form.trigger();
      if (name && isFormValid) {
        setFilters(
          {
            ...filterValues,
            [column]: get(values, sanitizeName(column)),
          },
          null,
        );
        // }
      }
    });
    return () => subscription.unsubscribe();
  }, [form, setFilters, filterValues, column]);

  return (
    <FormProvider {...form}>
      <DataTableFilterInput
        column={sanitizeName(column)}
        title={title}
        options={options}
      />
    </FormProvider>
  );
}

function DataTableFilterInput({
  column,
  title,
  options,
}: DataTableFilterProps) {
  const { field } = useController({ name: column });
  const [selectedValue, setSelectedValue] = React.useState(field.value);

  const onChange = (newValue) => {
    setSelectedValue(newValue);
    field.onChange(newValue);
  };

  React.useEffect(() => {
    if (field.value !== selectedValue && field.value === undefined) {
      setSelectedValue(field.value);
    }
  }, [field.value, selectedValue]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border-dashed">
          <PlusCircle className="mr-2 h-4 w-4" />
          {title}
          {selectedValue !== undefined && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <div className="hidden space-x-1 lg:flex">
                {options
                  .filter((option) => selectedValue === option.value)
                  .map((option) => (
                    <Badge
                      variant="secondary"
                      key={option.value?.toString() || option.label}
                      className="rounded-sm px-1 font-normal"
                    >
                      {option.label}
                    </Badge>
                  ))}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValue === option.value;
                return (
                  <CommandItem
                    key={option.value?.toString() || option.label}
                    onSelect={() => {
                      if (selectedValue !== undefined && isSelected) {
                        onChange(undefined);
                      } else {
                        onChange(option.value);
                      }
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-lg border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible',
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValue !== undefined && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => onChange(undefined)}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
