import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { AlertTriangle } from 'lucide-react';
import { DialogDescription } from '@/modules/ui/components/dialog';

/*
    The follwing checks need to happen
    - Patient has a valid email address 
    - Patient has a verified email address
    - User must confirm that they have the patient's consent to export their data
*/
export const PatientDataExportButton = () => {
  const record = useRecordContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const transform = (data) => ({
    ...data,
    patient_id: record.id,
    status: 'pending',
    type: 'patient_data_export',
    format: 'zip',
  });

  useEffect(() => {
    if (!record.user.email || !record.user.is_email_verified) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [record]);

  return (
    <CreateInDialogButton
      title="Request Data Export"
      resource="data-exports"
      transform={transform}
      refreshOnSuccess
      notifyMessage="Data export request submitted successfully"
      label="Start Data Export"
      saveButtonProps={{
        disabled: isDisabled,
      }}
      dialogVariant="shadcn"
    >
      <div className="flex flex-col space-y-4">
        <DialogDescription>
          This will begin a data export request for the patient. The data will
          be processed and then an email is sent to the patient with a link to
          download the data.
          <ul className="list-disc pl-4 space-y-2">
            <li>Only the patient can download their own data.</li>
            <li>You will not be able to download the data for the patient.</li>
          </ul>
        </DialogDescription>
        {(!record.user.email || !record.user.is_email_verified) && (
          <Alert variant="warning">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Data export unavailable</AlertTitle>
            <AlertDescription>
              {!record.user.email
                ? "Patient doesn't have an email address"
                : "Patient's email address is not verified"}
            </AlertDescription>
          </Alert>
        )}
        <SwitchInput
          source="consent_given"
          label="I have the patient's consent to export their data"
        />
      </div>
    </CreateInDialogButton>
  );
};
