import React, { useState } from 'react';
import {
  useCreate,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
  useGetOne,
} from 'react-admin';
import { Button } from '@/modules/ui/components/button';
import inflection from 'inflection';
import { get } from 'lodash';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog';
import { Label } from '@/modules/ui/components/label';
import { Input } from '@/modules/ui/components/input';
import { Switch } from '@/modules/ui/components/switch';
import { useAttendance } from '@/modules/attendance/hooks/UserCheckedIn';
import { Alert, AlertDescription } from '@/modules/ui/components/alert';
import { AlertTriangle } from 'lucide-react';

export function MarkConsentedButton() {
  const record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [acknowledgeWarning, setAcknowledgeWarning] = useState(false);

  const { data, isLoading } = useGetOne('patients', {
    id: `${record?.id}/can-be-consented`,
  });

  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { identity } = useGetIdentity();

  const {
    isCheckedIn,
    isLoading: attendanceLoading,
    attendanceEnforced,
  } = useAttendance();

  const countActiveCarePrograms = record?.service_instances.filter(
    (service_instance) => service_instance.is_active === true,
  ).length;

  if (!record || isLoading || attendanceLoading) {
    return null;
  }

  const onUpdateStatus = (newStatus) => {
    create(
      `patients/${record.id}/status`,
      {
        data: {
          patient_status: newStatus,
        },
      },
      {
        onSuccess: (data) => {
          refresh();
          logMonitoringNote(data);
          setShowDialog(false);
        },
        onError: (error) => {
          refresh();
          notify(`Unable to save changes: ${get(error, 'message')}`, {
            type: 'warning',
          });
        },
      },
    );
  };

  const logMonitoringNote = (data) => {
    const patient_id = get(record, 'id');

    create(`monitoring-activities`, {
      data: {
        patient_id: patient_id,
        practitioner_id: identity?.id,
        type: 'activity',
        description:
          'Patient status updated to ' +
          inflection.humanize(data.patient_status, true),
      },
    });
  };

  const handleSubmit = () => {
    if (dateOfBirth !== record.birthdate) {
      notify('Date of birth does not match patient record', { type: 'error' });
      return;
    }

    if (countActiveCarePrograms < 2 && !acknowledgeWarning) {
      notify('Please acknowledge the care program warning', { type: 'error' });
      return;
    }

    onUpdateStatus('consented');
  };

  const hasMetAttendanceTrackingRequirement = attendanceEnforced
    ? isCheckedIn
    : true;

  return (
    <>
      <Button
        className="w-fit mx-auto bg-green-500 hover:bg-green-700"
        disabled={
          !data?.can_be_consented || !hasMetAttendanceTrackingRequirement
        }
        onClick={() => setShowDialog(true)}
      >
        Mark Consented
      </Button>

      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Patient Consent</DialogTitle>
            <DialogDescription>
              Please ask and verify the patient's date of birth to consent
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="dob">Confirm Patient Date of Birth</Label>
              <Input
                id="dob"
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>

            {countActiveCarePrograms < 2 && (
              <Alert variant="warning">
                <AlertTriangle className="h-4 w-4" />
                <AlertDescription>
                  We typically expect at least two care programs to be added
                  before marking a patient as consented. Currently there{' '}
                  {countActiveCarePrograms === 1 ? 'is' : 'are'} only{' '}
                  {countActiveCarePrograms} active care program
                  {countActiveCarePrograms === 1 ? '' : 's'}.
                </AlertDescription>
                <div className="flex items-center space-x-2 mt-4">
                  <Switch
                    checked={acknowledgeWarning}
                    onCheckedChange={setAcknowledgeWarning}
                  />
                  <Label>I acknowledge and want to proceed anyway</Label>
                </div>
              </Alert>
            )}
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Confirm Consent</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
