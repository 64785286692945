import { fetchUtils } from 'react-admin';
import inMemoryJWT from './inMemoryJWT';
import { rollbar } from './rollbar';

export const httpClient = async (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = await inMemoryJWT.getToken('httpClient');

  options.headers.set('Authorization', `Bearer ${token}`);

  try {
    // Attempt to fetch the JSON response
    const response = await fetchUtils.fetchJson(url, options);
    return response;
  } catch (error: any) {
    // Check if the error status is 422 or 403
    if (error.status === 422 || error.status === 403) {
      // Log the error to Rollbar with contextual information
      rollbar.warning(`HTTP Error ${error.status} on ${url}`, {
        url,
        status: error.status,
        options,
        message: error.message,
        stack: error.stack,
      });
    }

    if (error.status === 422) {
      // Extract the first error message from the Pydantic error response
      // if .detail is an array, use the first element's .msg, otherwise use .detail
      const firstErrorMessage =
        error.body?.message || error.body?.detail || 'Unknown error';
      // Throw a new error with the formatted message
      throw new Error(firstErrorMessage);
    }

    if (error.status === 500) {
      // Log the error to Rollbar with contextual information
      rollbar.error(`HTTP Error ${error.status} on ${url}`, {
        url,
        status: error.status,
        options,
        message: error.message,
        stack: error.stack,
      });
      // Throw a new error with a generic message
      throw new Error('Internal server error');
    }

    // Re-throw the error to allow further handling if necessary
    throw error;
  }
};
