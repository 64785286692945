import { Box, Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { AlertCircle } from 'lucide-react';

function ErrorCard(props) {
  const { details } = props;
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="center" mt={4}>
          <Grid container>
            <Grid item xs={12}>
              <h6 className="text-lg font-semibold">An error has occurred!</h6>
              <p className="text-base font-normal">{details && details}</p>
            </Grid>
            <Grid item xs={12}>
              <AlertCircle />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ErrorCard;
