import React from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/modules/ui/components/table';
import { DataTablePagination } from '@/modules/data-table/components/data-table-pagination';
import { RecordContextProvider, useListContext } from 'react-admin';
import {
  ChevronDown,
  ChevronUp,
  ChevronsUpDown,
  InboxIcon,
} from 'lucide-react';
import { EmptyState } from './data-table-empty-state';
import { cn } from '@/modules/ui/utils/cn';

interface DataTableProps<TData> {
  columns: ColumnDef<TData, any>[];
  data?: TData[];
  loading?: boolean;
  emptyState?: React.ReactNode;
  onRowClick?: (row: TData) => void;
  tableClassName?: string;
}

const defaultEmptyState = (
  <EmptyState
    title="No records found"
    description="No records found"
    icons={[InboxIcon]}
  />
);

export function DataTable<TData>({
  columns,
  data: propData,
  loading: propLoading,
  onRowClick,
  emptyState = defaultEmptyState,
  tableClassName = '',
}: DataTableProps<TData>) {
  const {
    page,
    perPage,
    setPage,
    setPerPage,
    total,
    sort,
    setSort,
    data: tableData,
    isLoading: tableLoading,
  } = useListContext();
  const data = propData ?? tableData;
  const loading = propLoading ?? tableLoading;

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: {
        pageIndex: page - 1,
        pageSize: perPage,
      },
      sorting: sort ? [{ id: sort.field, desc: sort.order === 'DESC' }] : [],
    },
    pageCount: Math.ceil(total / perPage),
    rowCount: total,
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newState = updater({
          pageIndex: page - 1,
          pageSize: perPage,
        });

        if (newState.pageIndex !== page - 1) {
          setPage(newState.pageIndex + 1);
        }

        if (perPage !== newState.pageSize) {
          setPerPage(newState.pageSize);
        }
      }
    },
    onSortingChange: (updater) => {
      if (typeof updater === 'function') {
        const currentSort = sort
          ? [{ id: sort.field, desc: sort.order === 'DESC' }]
          : [];
        const newSort = updater(currentSort);
        if (newSort.length > 0) {
          setSort({
            field: newSort[0].id,
            order: newSort[0].desc ? 'DESC' : 'ASC',
          });
        } else {
          setSort({ field: 'id', order: 'ASC' });
        }
      }
    },
    manualPagination: true,
    manualSorting: true,
  });

  if (!data || data.length === 0) {
    if (loading) {
      return (
        <div className="w-full h-32 flex items-center justify-center">
          <LoadingSpinner />
        </div>
      );
    }
    return (
      <div className="mx-auto w-full flex justify-center">{emptyState}</div>
    );
  }

  return (
    <div className="space-y-4">
      <div className={cn('rounded-md border bg-white', tableClassName)}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    className={
                      header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : ''
                    }
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {{
                        asc: <ChevronUp className="ml-2 h-4 w-4" />,
                        desc: <ChevronDown className="ml-2 h-4 w-4" />,
                      }[header.column.getIsSorted() as string] ??
                        (header.column.getCanSort() && (
                          <ChevronsUpDown className="ml-2 h-4 w-4" />
                        ))}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center relative"
                >
                  <div className="absolute inset-0 flex items-center justify-center">
                    <LoadingSpinner />
                  </div>
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => onRowClick?.(row.original)}
                  className={
                    onRowClick ? 'cursor-pointer hover:bg-muted/50' : ''
                  }
                >
                  <RecordContextProvider value={row.original}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </RecordContextProvider>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {!loading && <DataTablePagination table={table} />}
    </div>
  );
}
