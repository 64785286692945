import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/modules/ui/components/dialog';
import { Button } from '@/modules/ui/components/button';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useGetIdentity } from 'ra-core';
import { useLogout } from 'ra-core';
import { useIsDemo } from '@/modules/demo/components/DemoProvider';
const env = window.config.env;

function TwoFactorEnforceDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { identity } = useGetIdentity();
  const logout = useLogout();
  const { isDemo } = useIsDemo();

  useEffect(() => {
    // if not prod disable
    if (env === 'dev' || isDemo) {
      return;
    }
    if (identity && location.pathname !== '/account') {
      if (!identity.mfa_enabled && identity.is_internal) {
        setIsOpen(true);
      }
    }
  }, [identity, location.pathname, isDemo]);

  const handlePressButton = () => {
    setIsOpen(false);
    navigate('/account');
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <Dialog open={isOpen} onOpenChange={null}>
      <DialogContent>
        <DialogTitle>Two Factor Authentication</DialogTitle>
        <DialogDescription>
          You must enable two-factor authentication to continue.
          <a
            href="https://www.notion.so/vironix/Setting-up-2-factor-Authentication-a768aabfb54944f58aa3f8c84e9d99aa?pvs=4"
            target="_blank"
            rel="noreferrer"
            className="text-sm text-blue-500 underline ml-2"
          >
            Learn more
          </a>
          Please log out and log back in after enabling two-factor
          authentication.
        </DialogDescription>
        <DialogFooter>
          <Button onClick={handlePressButton}>
            Enable Two-Factor Authentication
          </Button>
          <Button variant="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default TwoFactorEnforceDialog;
