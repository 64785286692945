import React, { useMemo } from 'react';

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarRail,
  useSidebar,
} from '@/modules/ui/components/sidebar';
import { SidebarLogo } from '../sidebar/components/SidebarLogo';
import { UserNav } from './UserNav';
import { FeedbackButton } from './FeedbackButton';
import { cn } from '@/modules/ui/utils/cn';
import { menuConfig } from '../sidebar/constants/menu-config';
import { usePermissions } from 'ra-core';
import { get, every } from 'lodash';
import { SidebarItem } from '../sidebar/components/SidebarItem';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/modules/ui/components/collapsible';
import { ChevronDown } from 'lucide-react';
import { ShowUpdate } from '@/modules/version/Version';
import { SidebarButton } from '../sidebar/components/SidebarButton';
import { useAttendance } from '@/modules/attendance/hooks/UserCheckedIn';

export function AppSidebar() {
  const { open, state } = useSidebar();
  const { permissions } = usePermissions();
  const { attendanceEnforced } = useAttendance();

  const filteredMenuConfig = useMemo(() => {
    return menuConfig
      .map((section) => {
        const items = section.resources.reduce((res, item) => {
          if (
            (item.roles && !item.roles.includes(get(permissions, 'role'))) ||
            (item.internal_user_only === true &&
              !get(permissions, 'is_internal')) ||
            (item.internal_user_only === false &&
              get(permissions, 'is_internal'))
          ) {
            return res;
          }

          if (item.dev_only && window.config.env === 'production') {
            return res;
          }

          if (item.attendanceTracking && !attendanceEnforced) {
            return res;
          }

          if (
            item.permissions &&
            !every(
              item.permissions,
              (permission) => !!get(permissions, permission),
            )
          ) {
            return res;
          }

          res.push(item);
          return res;
        }, []);

        return items.length
          ? {
              name: section.name,
              items,
              collapsible: section.collapsible,
              defaultOpen: section.defaultOpen,
            }
          : null;
      })
      .filter((section) => section !== null);
  }, [permissions, attendanceEnforced]);

  return (
    <Sidebar collapsible="icon" variant="sidebar">
      <SidebarHeader>
        <SidebarButton />
        <SidebarLogo />
        <ShowUpdate />
      </SidebarHeader>
      <SidebarContent>
        {filteredMenuConfig.map((section) =>
          state === 'collapsed' ? (
            <SidebarGroup key={section.name}>
              <SidebarGroupContent>
                <SidebarMenu>
                  {section.items.map((item) => (
                    <SidebarMenuItem key={item.name}>
                      <SidebarItem item={item} />
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          ) : section.collapsible === true ? (
            <Collapsible
              key={section.name}
              defaultOpen={section.defaultOpen}
              className="group/collapsible"
            >
              <SidebarGroup>
                {section.name && (
                  <SidebarGroupLabel asChild>
                    <CollapsibleTrigger className="w-full flex items-center">
                      {section.name}
                      <ChevronDown className="ml-auto h-4 w-4 transition-transform group-data-[state=open]/collapsible:rotate-180" />
                    </CollapsibleTrigger>
                  </SidebarGroupLabel>
                )}
                <CollapsibleContent>
                  <SidebarGroupContent>
                    <SidebarMenu>
                      {section.items.map((item) => (
                        <SidebarMenuItem key={item.name}>
                          <SidebarItem item={item} />
                        </SidebarMenuItem>
                      ))}
                    </SidebarMenu>
                  </SidebarGroupContent>
                </CollapsibleContent>
              </SidebarGroup>
            </Collapsible>
          ) : (
            <SidebarGroup key={section.name}>
              {section.name && (
                <SidebarGroupLabel>{section.name}</SidebarGroupLabel>
              )}
              <SidebarGroupContent>
                <SidebarMenu>
                  {section.items.map((item) => (
                    <SidebarMenuItem key={item.name}>
                      <SidebarItem item={item} />
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          ),
        )}
      </SidebarContent>
      <SidebarRail />
      <SidebarFooter>
        <div className="flex flex-col justify-center">
          <FeedbackButton />
          <div
            className={cn(
              'flex h-[52px] items-center justify-center',
              open ? 'px-2' : 'h-[52px]',
            )}
          >
            <UserNav />
          </div>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}
