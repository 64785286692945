import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { Badge } from '@/modules/ui/components/badge';
import { Button } from '@/modules/ui/components/button';
import inflection from 'inflection';
import { get } from 'lodash';
import { ArrowLeft } from 'lucide-react';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import {
  Datagrid,
  FunctionField,
  useDataProvider,
  useEvent,
  useRecordContext,
} from 'react-admin';
import { useInfiniteQuery } from 'react-query';
import { determineCellValue } from '../utils/determineCellValue';
import { MetricTableSidebar } from './MetricTableSidebar';
import { MetricsDialogContext } from './MetricsDialog';
import { chartProperties } from './chartProperties';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';

export function MetricsTableView() {
  const patientRecord = useRecordContext();
  const dataProvider = useDataProvider();

  const { measureName, setSelectedView, selectedRow, setSelectedRow } =
    useContext(MetricsDialogContext);

  const pagination = {
    page: 1,
    perPage: 50,
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['patients', 'table', measureName, { id: patientRecord?.id }],
      ({ pageParam = pagination.page }) =>
        dataProvider
          .getPatientResource(`charts`, {
            patient_id: patientRecord?.id,
            id: measureName,
            params: {
              timescale: null,
              skip: JSON.stringify(pagination.perPage * (pageParam - 1)),
              limit: JSON.stringify(pagination.perPage),
              order: 'desc',
              disable_flagged_filter: true,
            },
          })
          .then(({ data, total, pageInfo }) => ({
            data,
            total,
            pageInfo,
            pageParam,
          })),
      {
        getNextPageParam: (lastLoadedPage) => {
          if (lastLoadedPage.pageInfo) {
            return lastLoadedPage.pageInfo.hasNextPage
              ? lastLoadedPage.pageParam + 1
              : undefined;
          }
          const totalPages = Math.ceil(
            (lastLoadedPage.total || 0) / pagination.perPage,
          );

          return lastLoadedPage.pageParam < totalPages
            ? Number(lastLoadedPage.pageParam) + 1
            : undefined;
        },
        getPreviousPageParam: (lastLoadedPage) => {
          if (lastLoadedPage.pageInfo) {
            return lastLoadedPage.pageInfo.hasPreviousPage
              ? lastLoadedPage.pageParam - 1
              : undefined;
          }

          return lastLoadedPage.pageParam === 1
            ? undefined
            : lastLoadedPage.pageParam - 1;
        },
      },
    );

  const unwrappedData = useMemo(
    () =>
      data?.pages?.reduce(
        (acc, page) => [...acc, ...(page.data.points || [])],
        [],
      ),
    [data],
  );

  const observerElem = useRef(null);
  const handleObserver = useEvent<[IntersectionObserverEntry[]], void>(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  );

  useEffect(() => {
    const element = observerElem.current;
    if (!element) return;
    const observer = new IntersectionObserver(handleObserver, { threshold: 0 });
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [
    fetchNextPage,
    hasNextPage,
    handleObserver,
    isLoading,
    isFetchingNextPage,
  ]);

  const handleGoBack = () => {
    setSelectedRow(null);
    setSelectedView('chart');
  };

  if (!unwrappedData) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-row items-center gap-4">
        <Button variant="default" className="h-8 " onClick={handleGoBack}>
          <ArrowLeft className="w-4 h-4" />
        </Button>
        <h6 className="text-lg font-semibold text-left">
          All {get(chartProperties, [get(data, '0.chart_name'), 'name'])}{' '}
          measurements
        </h6>
      </div>
      <div className="flex flex-row no-wrap gap-4">
        <div className="w-2/3 h-full p-4">
          <Datagrid
            data={unwrappedData}
            isLoading={isLoading}
            sort={{ field: 'x', order: 'DESC' }}
            rowClick={(recordId) => setSelectedRow(recordId)}
            rowSx={(record, index) => ({
              backgroundColor:
                selectedRow === record.id
                  ? 'rgba(0, 0, 0, 0.04)'
                  : 'transparent',
            })}
          >
            <LuxonDateField source="x" label="Timestamp" showTime />
            <FunctionField
              source="y"
              label="Value"
              render={(record) =>
                determineCellValue({
                  row: record,
                  chartName: get(data, 'pages.0.data.chart_name'),
                  chartProperties,
                })
              }
            />
            <FunctionField
              source="annotations"
              render={(record) =>
                record.annotations?.map((annotation) => (
                  <Badge variant="outline">
                    {inflection.humanize(annotation?.annotation_reason)}
                  </Badge>
                ))
              }
            />
          </Datagrid>
        </div>
        <div className="w-1/3 ">
          <MetricTableSidebar data={data} />
        </div>
      </div>
      <div ref={observerElem} className="py-2 text-center">
        {isFetchingNextPage && hasNextPage && <LoadingSpinner />}
      </div>
    </div>
  );
}
