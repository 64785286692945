import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import React, { cloneElement } from 'react';

function ChartDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // // @ts-ignore
      // maxWidth={'md'}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Information regarding algorithm scores
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          <p className="text-base font-normal">
            Using sophisticated machine learning algorithms, Vironix interprets
            patients' signs and symptoms within the context of their normal
            health to assess the severity of their condition.
          </p>
          <p className="text-base font-normal">
            Exacerbation risk scores range from 0-100, with higher scores
            indicating a higher risk of exacerbation.
          </p>
          <p className="text-base font-normal">
            Vital & symptom severity scores have a 5-tier scale, with higher
            tiers indicating more severe vitals or symptoms. The tiers are as
            follows:
            <ul>
              <li>Low</li>
              <li>Low - Medium</li>
              <li>Medium</li>
              <li>Medium - High</li>
              <li>High</li>
            </ul>
          </p>
          <span className="text-xs font-normal">
            Vironix is not a substitute for professional medical consultation.
          </span>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export function ScoreChart({ element }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (_value: string) => {
    setOpen(false);
  };

  return (
    <>
      {cloneElement(element, {
        handleIndicatorClick: () => {
          setOpen(true);
        },
      })}
      <ChartDialog open={open} onClose={handleClose} />
    </>
  );
}
