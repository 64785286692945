import { Textarea } from '@/modules/ui/components/textarea';
import { required, useInput } from 'ra-core';
import {
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
} from '@/modules/ui/components/form';
import { InputMessage } from './input-message';
import inflection from 'inflection';
import React, { forwardRef, useCallback } from 'react';

const detectDelimiter = (text: string) => {
  const delimiters = [
    ',', // CSV
    '\t', // Tab
    ';', // Semicolon (common in European CSV)
    '|', // Pipe
    ' ', // Space
    '\n', // Newline
  ];

  // Check for common Excel/Google Sheets paste patterns first
  if (text.includes('\n') && (text.includes('\t') || text.includes(','))) {
    return text.includes('\t') ? '\t' : ',';
  }

  const counts = delimiters.map((d) => ({
    delimiter: d,
    count: (text.match(new RegExp(d === '|' ? '\\|' : d, 'g')) || []).length,
  }));

  const maxCount = Math.max(...counts.map((c) => c.count));
  const found = counts.find((c) => c.count === maxCount);
  return found ? found.delimiter : ',';
};

export const DelimitedTextInput = forwardRef((props: any, ref) => {
  const {
    className,
    defaultValue,
    label,
    format,
    onBlur,
    onChange,
    helperText,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;

  const validateEqualLength = (value: any) => {
    if (!value) {
      return undefined;
    }

    const values = Array.isArray(value)
      ? value
      : value.split('\n').filter((s) => s.toString().trim().length > 0);

    if (values.length <= 1) {
      return undefined;
    }

    const stringValues = values.map((v) => v.toString().trim());
    const firstLength = stringValues[0].length;

    if (!stringValues.every((v) => v.length === firstLength)) {
      return 'All items must have the same length';
    }

    return undefined;
  };

  const finalValidate = validate
    ? [validate, validateEqualLength]
    : [validateEqualLength, required()];

  const {
    field,
    fieldState: { error, isTouched },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format: (value) => value || '',
    parse: (value) => {
      if (!value) return '';
      return value.split('\n').filter((s) => s.trim().length > 0);
    },
    resource,
    source,
    validate: finalValidate,
    onBlur,
    onChange,
    ...rest,
  });

  const handlePaste = useCallback(
    (e: React.ClipboardEvent) => {
      e.preventDefault();
      const pastedText = e.clipboardData.getData('text');
      const delimiter = detectDelimiter(pastedText);

      // Split by delimiter and clean up whitespace
      const values = pastedText
        .split(delimiter)
        .map((s) => s.trim())
        .filter((s) => s.length > 0)
        .join('\n');

      field.onChange(values);
    },
    [field],
  );

  let showLabel: string | boolean = label;
  if (label === undefined || label === null) {
    showLabel = inflection.humanize(source);
  }

  return (
    <FormItem id={id}>
      {showLabel && (
        <FormLabel>
          {showLabel}
          {isRequired && <span className="">*</span>}
        </FormLabel>
      )}
      <FormControl>
        <Textarea
          {...field}
          className={className}
          ref={ref}
          onPaste={handlePaste}
          {...rest}
        />
      </FormControl>
      {helperText && <FormDescription>{helperText}</FormDescription>}
      <InputMessage touched={isTouched} error={error?.message} />
    </FormItem>
  );
});

DelimitedTextInput.displayName = 'DelimitedTextInput';
