import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { Button } from '@/modules/ui/components/button';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';

import BadgeField from '@/modules/ra-ui/components/badge-field';
import { get } from 'lodash';

export const InvoicesList = ({ providerId }: { providerId?: number }) => {
  const record = useRecordContext();

  const tenantId = get(record, 'id') || providerId;

  const sort = { field: null, order: null };

  const { data, total, isLoading } = useGetList(
    `providers/${tenantId}/invoices`,
    {
      pagination: { page: 1, perPage: 20 },
      filter: {},
      sort: sort,
    },
  );
  if (!tenantId) {
    return null;
  }

  return (
    <Datagrid
      data={data}
      total={total}
      isLoading={isLoading}
      sort={sort}
      bulkActionButtons={false}
      setSort={null}
      empty={
        <EmptyDatagrid
          resourceName="Invoices"
          textFormatString="No invoices found for this provider"
        />
      }
    >
      <FunctionField
        render={(record) => `$   ${(record.total / 100).toFixed(2)}`}
        label="Amount Due"
      />
      <TextField source="number" />
      <BadgeField source="status" />
      <LuxonDateField source="created" showTime={false} label="Date" />
      <FunctionField
        render={(record) => (
          <>
            {record.hosted_invoice_url && (
              <a
                href={record.hosted_invoice_url}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="h-8 mr-2" variant="outline">
                  Invoice
                </Button>
              </a>
            )}
            {record.detailed_pdf && (
              <a href={record.detailed_pdf} target="_blank" rel="noreferrer">
                <Button className="h-8" variant="outline">
                  Detailed
                </Button>
              </a>
            )}
          </>
        )}
      />
    </Datagrid>
  );
};
