'use client';

import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';

import { cn } from '@/modules/ui/utils/cn';
import { Button } from '@/modules/ui/components/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/modules/ui/components/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/modules/ui/components/popover';
import { useGetList, useGetOne, useInput, FieldTitle } from 'ra-core';
import { useCallback, useMemo, useRef } from 'react';
import { get, debounce } from 'lodash';
import { FormLabel } from '@/modules/ui/components/form';
import { InputMessage } from './input-message';

interface AutocompleteInputProps {
  defaultValue?: any;
  isRequired?: boolean;
  disabled?: boolean;
  source: string;
  resource?: string;
  label?: string | React.ReactElement;
  optionText?: string;
  optionValue?: string;
  filterToQuery?: (query: string) => any;
  modal?: boolean;
  reference: string;
  filter?: Record<string, any> | null;
  noOptionsText?: string;
  searchPlaceholder?: string;
  helperText?: string | React.ReactElement | false;
  className?: string;
  validate?: any;
}

export function AutocompleteInput({
  defaultValue,
  isRequired: isRequiredOverride,
  disabled,
  source,
  resource,
  label,
  optionText = 'name',
  optionValue = 'id',
  filterToQuery,
  modal,
  reference,
  filter = null,
  noOptionsText = 'No results found',
  searchPlaceholder = 'Search ...',
  helperText = false,
  className,
  validate,
}: AutocompleteInputProps) {
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');

  const {
    field,
    isRequired,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    defaultValue,
    isRequired: isRequiredOverride,
    resource,
    source,
    disabled,
    validate,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const delayedSearch = useRef(
    debounce((q: string) => {
      setSearchQuery(q);
    }, 300),
  ).current;

  const handleSearch = (query: string) => {
    delayedSearch(query);
  };

  const isOptionEqualToValue = (option: any, value: any) => {
    return String(getChoiceValue(option)) === String(getChoiceValue(value));
  };

  const getSelectedOptionLabel = (selectedValue: any) => {
    const option = finalChoices.filter(
      (option) => getChoiceValue(option) === selectedValue,
    )[0];
    return getOptionLabel(option);
  };

  const getOptionLabel = (option: any) => {
    return get(option, optionText);
  };

  const getChoiceValue = useCallback(
    (option: any) => {
      return get(option, optionValue);
    },
    [optionValue],
  );

  const finalFilter = {
    ...(filter ? filter : {}),
    ...(filterToQuery ? filterToQuery(searchQuery) : {}),
  };

  const { data, error: fetchError } = useGetList(
    reference,
    {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 10 },
      filter: finalFilter,
    },
    {
      enabled: open,
    },
  );

  const { data: dataSingle } = useGetOne(
    reference,
    {
      id: field.value,
    },
    {
      enabled: !!field.value,
    },
  );

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setSearchQuery('');
    }
    setOpen(open);
  };

  const finalChoices = useMemo(() => {
    const choices = data || [];
    if (!dataSingle) {
      return choices;
    }

    const uniqueChoices = new Set(
      [...choices, dataSingle].map((choice) =>
        JSON.stringify({ value: getChoiceValue(choice), data: choice }),
      ),
    );

    return Array.from(uniqueChoices).map((choice) => JSON.parse(choice).data);
  }, [data, dataSingle, getChoiceValue]);

  const renderHelperText =
    !!fetchError ||
    helperText !== false ||
    ((isTouched || isSubmitted) && invalid);

  return (
    <div className={cn('space-y-2', className)}>
      <FormLabel>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </FormLabel>
      <div>
        <Popover open={open} onOpenChange={handleOpenChange} modal={modal}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className={cn(
                'w-full justify-between h-10',
                !field.value && 'text-muted-foreground',
                disabled && 'opacity-50 cursor-not-allowed',
              )}
              disabled={disabled}
            >
              {field.value ? getSelectedOptionLabel(field.value) : 'Select ...'}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0">
            <Command shouldFilter={false}>
              <CommandInput
                placeholder={searchPlaceholder}
                ref={inputRef}
                onValueChange={handleSearch}
              />
              <CommandList>
                <CommandEmpty>{noOptionsText}</CommandEmpty>
                <CommandGroup>
                  {finalChoices.map((option) => {
                    const value = getChoiceValue(option);
                    const label = getOptionLabel(option);

                    return (
                      <CommandItem
                        key={value}
                        onSelect={() => {
                          field.onChange(value);
                          setOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            isOptionEqualToValue(option, field.value)
                              ? 'opacity-100'
                              : 'opacity-0',
                          )}
                        />
                        {label} <span className="hidden">{value}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      {renderHelperText && (
        <InputMessage
          touched={isTouched || isSubmitted || !!fetchError}
          error={error?.message || fetchError?.message}
          helperText={helperText}
        />
      )}
    </div>
  );
}
