import React from 'react';
import {
  useCreate,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'ra-core';
import { Button } from '@/modules/ui/components/button';
import { get } from 'lodash';
export const ValidatePatientDataButton = () => {
  const [create, { isLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const { permissions } = usePermissions();

  if (!get(permissions, 'data_export.validate')) return null;

  const handleValidate = () => {
    create(
      'data-exports/validate',
      { data: { export_request_id: record?.id } },
      {
        onSuccess: () => {
          notify('Data export validated successfully');
          refresh();
        },
      },
    );
  };

  return (
    <Button
      size="sm"
      variant="outline"
      onClick={handleValidate}
      disabled={isLoading}
    >
      {isLoading ? 'Validating...' : 'Validate'}
    </Button>
  );
};
