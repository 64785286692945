import React from 'react';
import { useRecordContext } from 'ra-core';
import { useQuery } from 'react-query';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/modules/ui/components/dialog';
import { Button } from '@/modules/ui/components/button';
import { Info, MapPin } from 'lucide-react';
import { DateTimeProviderTZField } from '@/modules/ra-ui/components/DateTimeProviderTZField';
import { formatDurationAbbreviated } from '@/utils/formatDurationAbbreviated';
import { Separator } from '@/modules/ui/components/separator';
import { Badge } from '@/modules/ui/components/badge';
import { get } from 'lodash';
import { ReferenceField, TextField } from 'react-admin';

interface AttendanceInfoButtonProps {
  buttonProps?: {
    variant?:
      | 'default'
      | 'destructive'
      | 'outline'
      | 'secondary'
      | 'ghost'
      | 'link';
    className?: string;
  };
}

interface LocationInfo {
  ipAddress: string;
  latitude: number;
  longitude: number;
  cityName: string;
  regionName: string;
  countryName: string;
}

function LocationMap({
  latitude,
  longitude,
}: { latitude: number; longitude: number }) {
  return (
    <div className="space-y-2">
      <iframe
        width="100%"
        height="300"
        src={`https://www.openstreetmap.org/export/embed.html?bbox=${longitude - 0.002},${latitude - 0.002},${longitude + 0.002},${latitude + 0.002}&layer=mapnik&marker=${latitude},${longitude}`}
        style={{ border: '1px solid black' }}
        title="Location Map"
      ></iframe>
      <p className="text-sm text-yellow-600 flex items-center gap-1">
        <Info className="h-4 w-4" />
        Location is approximate based on browser geolocation.
      </p>
    </div>
  );
}

export function AttendanceInfoButton({
  buttonProps = {
    variant: 'ghost',
    className: null,
  },
}: AttendanceInfoButtonProps) {
  const [open, setOpen] = React.useState(false);
  const record = useRecordContext();

  const fetchLocationInfo = async (ip: string): Promise<LocationInfo> => {
    const response = await fetch(`https://freeipapi.com/api/json/${ip}`);
    return response.json();
  };

  const { data: checkInLocation, isLoading: loadingCheckIn } = useQuery(
    ['location-info', record?.ip_address_checked_in],
    () => fetchLocationInfo(record?.ip_address_checked_in),
    {
      enabled: !!record?.ip_address_checked_in && open,
    },
  );

  const { data: checkOutLocation, isLoading: loadingCheckOut } = useQuery(
    ['location-info', record?.ip_address_checked_out],
    () => fetchLocationInfo(record?.ip_address_checked_out),
    {
      enabled: !!record?.ip_address_checked_out && open,
    },
  );

  if (!record) return null;

  const calculateDuration = () => {
    if (!record.check_in_time || !record.check_out_time) return null;
    const checkIn = new Date(record.check_in_time);
    const checkOut = new Date(record.check_out_time);
    return Math.floor((checkOut.getTime() - checkIn.getTime()) / 1000); // Duration in seconds
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant={buttonProps.variant}
          size="sm"
          className={buttonProps.className}
        >
          <Info className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Attendance Details</DialogTitle>
          <DialogDescription>
            View detailed information about this attendance record
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-medium text-gray-700">Employee</h4>
              <p>{get(record, 'user_fullname', '-')}</p>
            </div>
            <div>
              <h4 className="font-medium text-gray-700">Location</h4>
              <ReferenceField
                reference="locations"
                source="location_id"
                link={(record) => `/locations/${record.id}/show`}
              >
                <TextField source="name" />
              </ReferenceField>
            </div>

            <div>
              <h4 className="font-medium text-gray-700">Check-in Time</h4>
              <DateTimeProviderTZField
                source="check_in_time"
                showTime
                showTZ
                tenant_id={record.tenant_id}
              />
            </div>
            <div>
              <h4 className="font-medium text-gray-700">Check-out Time</h4>
              {record.check_out_time ? (
                <DateTimeProviderTZField
                  source="check_out_time"
                  showTime
                  showTZ
                  tenant_id={record.tenant_id}
                />
              ) : (
                <Badge variant="outline">Still Checked In</Badge>
              )}
            </div>

            {calculateDuration() && (
              <div>
                <h4 className="font-medium text-gray-700">Duration</h4>
                <p>{formatDurationAbbreviated(calculateDuration())}</p>
              </div>
            )}

            <div>
              <h4 className="font-medium text-gray-700">Attendance Type</h4>
              <Badge variant="outline">
                {record.attendance_type?.toUpperCase() || 'OFFICE'}
              </Badge>
            </div>
          </div>

          <Separator />

          <div>
            <h4 className="font-medium text-gray-700 mb-2">
              Check-in Location
            </h4>
            {loadingCheckIn ? (
              <div className="bg-gray-50 p-4 rounded-lg text-gray-500">
                Loading location information...
              </div>
            ) : checkInLocation ||
              (record.latitude_checked_in && record.longitude_checked_in) ? (
              <div className="bg-gray-50 p-4 rounded-lg space-y-2">
                {checkInLocation && (
                  <>
                    <div className="flex items-center gap-2">
                      <MapPin className="h-4 w-4 text-gray-500" />
                      <span>
                        {checkInLocation.cityName}, {checkInLocation.regionName}
                        , {checkInLocation.countryName}
                      </span>
                    </div>
                    <div className="text-sm text-gray-500">
                      IP: {record.ip_address_checked_in}
                    </div>
                  </>
                )}
                {record.latitude_checked_in && record.longitude_checked_in && (
                  <LocationMap
                    latitude={record.latitude_checked_in}
                    longitude={record.longitude_checked_in}
                  />
                )}
              </div>
            ) : (
              <div className="bg-gray-50 p-4 rounded-lg text-gray-500">
                No location information available
              </div>
            )}
          </div>

          {record.check_out_time && (
            <>
              <Separator />
              <div>
                <h4 className="font-medium text-gray-700 mb-2">
                  Check-out Location
                </h4>
                {loadingCheckOut ? (
                  <div className="bg-gray-50 p-4 rounded-lg text-gray-500">
                    Loading location information...
                  </div>
                ) : checkOutLocation ||
                  (record.latitude_checked_out &&
                    record.longitude_checked_out) ? (
                  <div className="bg-gray-50 p-4 rounded-lg space-y-2">
                    {checkOutLocation && (
                      <>
                        <div className="flex items-center gap-2">
                          <MapPin className="h-4 w-4 text-gray-500" />
                          <span>
                            {checkOutLocation.cityName},{' '}
                            {checkOutLocation.regionName},{' '}
                            {checkOutLocation.countryName}
                          </span>
                        </div>
                        <div className="text-sm text-gray-500">
                          IP: {record.ip_address_checked_out}
                        </div>
                      </>
                    )}
                    {record.latitude_checked_out &&
                      record.longitude_checked_out && (
                        <LocationMap
                          latitude={record.latitude_checked_out}
                          longitude={record.longitude_checked_out}
                        />
                      )}
                  </div>
                ) : (
                  <div className="bg-gray-50 p-4 rounded-lg text-gray-500">
                    No location information available
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
