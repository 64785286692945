import React, { useEffect, useState } from 'react';
import { Howl } from 'howler';
import { Button } from '@/modules/ui/components/button';
import { Slider } from '@/modules/ui/components/slider';
import { Play, Pause } from 'lucide-react';

const CallRecordingPlayer = ({ url, isActive }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [sound, setSound] = useState(null);
  const [duration, setDuration] = useState(0); // Audio duration in seconds
  const [seek, setSeek] = useState(0); // Current playback position

  useEffect(() => {
    let interval;

    if (isPlaying && sound) {
      // Update the seek position every 500ms
      interval = setInterval(() => {
        setSeek(sound.seek());
      }, 500);
    }

    return () => clearInterval(interval);
  }, [isPlaying, sound]);

  useEffect(() => {
    if (!isActive) {
      sound?.stop();
    }
  }, [isActive, sound]);

  const initializeSound = () => {
    const newSound = new Howl({
      src: [url],
      html5: true,
      onplay: () => setDuration(newSound.duration()),
      onend: () => setIsPlaying(false),
    });
    setSound(newSound);
    return newSound;
  };

  const togglePlay = () => {
    if (!sound) {
      const newSound = initializeSound();
      newSound.play();
    } else if (isPlaying) {
      sound.pause();
    } else {
      sound.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="flex flex-col space-y-6 p-4 border rounded-lg shadow w-full">
      <div className="flex items-center justify-center">
        <Button onClick={togglePlay} size="icon" variant="ghost">
          {isPlaying ? (
            <Pause className="h-6 w-6" />
          ) : (
            <Play className="h-6 w-6" />
          )}
        </Button>
      </div>

      <div className="flex flex-col space-y-3">
        <Slider
          min={0}
          max={duration || 0}
          step={0.1}
          value={[seek]}
          onValueChange={([value]) => {
            setSeek(value);
            sound?.seek(value);
          }}
          className="w-full"
        />
        <div className="flex justify-between text-sm text-muted-foreground px-1">
          <span>{formatTime(seek)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

export default CallRecordingPlayer;
