import AppTitle from '@/modules/layout/components/app-title';
import { DataTable } from '@/modules/data-table/components/data-table';
import React, { FC } from 'react';
import { ListContextProvider, SortPayload, useGetList } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { createColumnHelper } from '@tanstack/react-table';
import { Badge } from '@/modules/ui/components/badge';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { titleize } from 'inflection';

export const DeviceIssues: FC = () => {
  return (
    <>
      <AppTitle title="Device Issues" />
      <DeviceIssuesTable />
    </>
  );
};

type DeviceIssuesColumns = {
  id: number;
  created_on: string;
  actor_name: string;
  device_id: number;
  value: any;
  device_type: string;
};

const columnHelper = createColumnHelper<DeviceIssuesColumns>();
const columns = [
  columnHelper.accessor('device_type', {
    header: () => 'Device',
    cell: (info) => (
      <Badge variant="outline">{titleize(info.getValue())}</Badge>
    ),
    enableSorting: true,
  }),
  columnHelper.accessor('value', {
    header: () => 'Issue',
    cell: (info) => {
      const value = info.getValue();
      if (value?.message) {
        return value.message;
      }
      if (value?.issue_type) {
        return `${titleize(value.issue_type)}: ${value.description}`;
      }
      return JSON.stringify(value);
    },
    enableSorting: false,
  }),
  columnHelper.accessor('created_on', {
    header: () => 'Date',
    cell: (info) => {
      const date = DateTime.fromISO(info.getValue(), { zone: 'utc' }).setZone(
        'local',
      );
      const dateString = date.toLocaleString(DateTime.DATETIME_MED);
      const tzAbbreviation = date.toFormat('ZZZZ');
      return (
        <span>
          {dateString}
          <Badge variant="outline" className="ml-1 px-1.5">
            {tzAbbreviation}
          </Badge>
        </span>
      );
    },
    enableSorting: true,
  }),
  columnHelper.accessor('actor_name', {
    header: () => 'Reported By',
    cell: (info) => info.getValue(),
    enableSorting: true,
  }),
];

const DeviceIssuesTable = (props) => {
  const navigate = useNavigate();

  const [sort, setSort] = React.useState({
    field: 'created_on',
    order: 'DESC',
  });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [filterValues, setFilters] = React.useState({});

  const { data, total, isLoading } = useGetList('device-activities', {
    pagination: { page, perPage: 20 },
    sort: sort as SortPayload,
    filter: {
      'activity_type[eq]': 'issue_report',
    },
  });

  if (!data || data.length === 0) {
    return (
      <EmptyDatagrid
        resourceName="Device Activities"
        textFormatString="No issues found."
        icon={false}
      />
    );
  }

  return (
    <ListContextProvider
      value={
        {
          data,
          total,
          isLoading,
          page,
          setPage,
          perPage,
          setPerPage,
          filterValues,
          setFilters,
          sort,
          setSort,
        } as any
      }
    >
      <DataTable
        columns={columns}
        onRowClick={(row) => {
          navigate(`/devices/${row.device_id}/show`);
        }}
      />
    </ListContextProvider>
  );
};
