import * as React from 'react';
import { useInput, FieldTitle } from 'ra-core';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/modules/ui/components/radio-group';
import { cn } from '@/modules/ui/utils/cn';
import { FormLabel } from '@/modules/ui/components/form';
import { InputMessage } from '@/modules/ui/inputs/input-message';

export interface YesNoRadioInputProps {
  source: string;
  label?: string | React.ReactElement;
  helperText?: string | React.ReactElement | false;
  validate?: any;
  className?: string;
  required?: boolean;
}

export const YesNoRadioInput = (props: YesNoRadioInputProps) => {
  const { source, label, helperText, validate, className, required, ...rest } =
    props;

  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    source,
    validate,
    ...rest,
  });

  const renderHelperText =
    helperText !== false || ((isTouched || isSubmitted) && invalid);

  return (
    <div className={cn('space-y-2 mb-2', className)}>
      <FormLabel>
        <FieldTitle label={label} source={source} isRequired={isRequired} />
      </FormLabel>
      <RadioGroup
        onValueChange={field.onChange}
        defaultValue={String(field.value)}
        className="flex flex-row space-x-4"
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="true" id={`${source}_yes`} />
          <label
            htmlFor={`${source}_yes`}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Yes
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="false" id={`${source}_no`} />
          <label
            htmlFor={`${source}_no`}
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            No
          </label>
        </div>
      </RadioGroup>
      {renderHelperText && (
        <InputMessage
          touched={isTouched || isSubmitted}
          error={error?.message}
          helperText={helperText}
        />
      )}
    </div>
  );
};
