import React from 'react';
import { Button } from '@/modules/ui/components/button';
import { useGetIdentity } from 'ra-core';
import { useSidebar } from '@/modules/ui/components/sidebar';
import { cn } from '@/modules/ui/utils/cn';
import { MessageSquare } from 'lucide-react';

export const FeedbackButton = () => {
  const { open } = useSidebar();
  const { identity } = useGetIdentity();

  if (!identity?.is_internal) return null;

  return (
    <Button
      variant="ghost"
      size={open ? 'default' : 'icon'}
      data-featurebase-feedback
      className={cn(
        'w-full  flex items-center justify-center ',
        open ? 'px-2' : 'justify-center',
      )}
    >
      <MessageSquare className={cn('h-4 w-4', open ? 'mr-2' : '')} />
      {open && 'Feedback'}
    </Button>
  );
};
