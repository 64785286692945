import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useGetIdentity, useRecordContext } from 'react-admin';
import { Button } from '@/modules/ui/components/button';
import { Phone } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/modules/ui/components/dialog';
import { get } from 'lodash';
import {
  RadioGroup,
  RadioGroupItem,
} from '@/modules/ui/components/radio-group';
import { Label } from '@/modules/ui/components/label';
import { usePermissions } from 'react-admin';
import {
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { TelnyxRTCContext } from '@telnyx/react-client';
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from '@/modules/ui/components/select';
import { FormLabel } from '@mui/material';
import { useIsDemo } from '@/modules/demo/components/DemoProvider';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTelnyxToken } from '../hooks/useTelnyxToken';
import { phoneNumberTypeMapping } from '@/modules/patients/constants/phoneNumberTypeMapping';
import { PhoneNumberInput } from '@/modules/ra-ui/components/PhoneNumberInput';
import { FormProvider } from 'react-hook-form';

function phone(schema: z.ZodString | z.ZodOptional<z.ZodString>) {
  return schema
    .refine(
      (value) => !value || isValidPhoneNumber(value),
      'Please specify a valid phone number (include the international prefix).',
    )
    .transform((value) =>
      value ? parsePhoneNumberFromString(value).number.toString() : undefined,
    );
}

const callFormSchema = z.object({
  phoneType: z.string().optional(),
  overrideNumber: phone(z.string().optional()),
  inputDevice: z.string().min(1, 'Please select a microphone'),
});

type CallFormData = z.infer<typeof callFormSchema>;

export function CallPatientButton() {
  const record = useRecordContext();
  const [open, setOpen] = React.useState(false);
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const client = useContext(TelnyxRTCContext);
  const { isDemo } = useIsDemo();
  const { error, phoneNumber } = useTelnyxToken();
  const forceOverride = isDemo || window.config.env !== 'production';

  const [audioDevices, setAudioDevices] = useState({ input: [], output: [] });
  const [isCallActive, setIsCallActive] = useState(false);

  const isAdmin = ['superadmin', 'internal_admin'].includes(identity?.role);

  const form = useForm<CallFormData>({
    resolver: zodResolver(callFormSchema),
    defaultValues: {
      phoneType: '',
      overrideNumber: '',
      inputDevice: '',
    },
  });

  useEffect(() => {
    if (!open) return;
    const getAudioDevices = async () => {
      const inputDevices = await client.getAudioInDevices();
      const outputDevices = await client.getAudioOutDevices();
      setAudioDevices({ input: inputDevices, output: outputDevices });

      // Set initial input device to the active one if available
      const activeInputDevice = inputDevices.find(
        (device) => device.deviceId === client.micId,
      );
      if (activeInputDevice) {
        form.setValue('inputDevice', activeInputDevice.deviceId);
      } else if (inputDevices.length > 0) {
        form.setValue('inputDevice', inputDevices[0].deviceId);
      }
    };

    getAudioDevices();
  }, [client, form, open]);

  useEffect(() => {
    const handleNotification = (notification) => {
      if (notification.type === 'callUpdate') {
        const call = notification.call;

        if (['hangup', 'destroy', 'purge'].includes(call.state)) {
          setIsCallActive(false);
        } else {
          setIsCallActive(true);
        }
      }
    };

    client.on('telnyx.notification', handleNotification);

    return () => {
      client.off('telnyx.notification', handleNotification);
    };
  }, [client]);

  const availablePhoneNumbers = useMemo(() => {
    if (!record) return [];
    const phoneTypes = Object.keys(phoneNumberTypeMapping);
    return phoneTypes
      .map((type) => ({
        type,
        number: get(record, `user.${type}_phone_number`),
        label: phoneNumberTypeMapping[type].title,
      }))
      .filter((phone) => phone.number);
  }, [record]);

  useEffect(() => {
    if (availablePhoneNumbers.length > 0 && !form.getValues('phoneType')) {
      form.setValue('phoneType', availablePhoneNumbers[0].type);
    }
  }, [availablePhoneNumbers, form]);

  if (!record) return null;

  if (!get(permissions, 'voice_calls.create')) return null;
  if (error) return null;

  const handleCall = async (data: CallFormData) => {
    if (!data.phoneType && !data.overrideNumber) return;
    if (forceOverride && !data.overrideNumber) return;

    const destinationNumber =
      forceOverride || data.overrideNumber
        ? data.overrideNumber
        : get(record, `user.${data.phoneType}_phone_number`);

    await client.newCall({
      destinationNumber,
      callerName: phoneNumber,
      callerNumber: phoneNumber,
      audio: true,
      video: false,
      // id: `patient_id=${record.id};caller_id=${identity.id};${new Date().getTime()}`,
      customHeaders: [
        {
          name: 'X-RTC-CALLID',
          value: `patient_id=${record.id};caller_id=${identity.id};${new Date().getTime()}`,
        },
      ],
    });

    setOpen(false);
  };

  const handleSelectDevice = async (deviceId: string) => {
    const device = audioDevices.input.find((d) => d.deviceId === deviceId);
    if (!device) return;
    await client.setAudioSettings({
      micId: device.deviceId,
      micLabel: device.label,
    });
    form.setValue('inputDevice', deviceId);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="w-fit"
          onClick={() => setOpen(true)}
          disabled={!availablePhoneNumbers.length || isCallActive}
        >
          <Phone className="mr-2 h-4 w-4" />
          Call Patient
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Call Patient</DialogTitle>
          <DialogDescription>
            Please select a phone number to call the patient.
          </DialogDescription>
        </DialogHeader>
        <FormProvider {...form}>

        <form onSubmit={form.handleSubmit(handleCall)} className="space-y-4">
          {(!forceOverride || isAdmin) && (
            <RadioGroup
              value={form.watch('phoneType')}
              onValueChange={(value) => form.setValue('phoneType', value)}
              className="grid grid-cols-1 gap-2"
            >
              {availablePhoneNumbers.map((phone) => {
                const parsedNumber = parsePhoneNumberFromString(phone.number);
                const formattedNumber = parsedNumber
                  ? parsedNumber.formatNational()
                  : phone.number;

                return (
                  <Label
                    key={phone.type}
                    className={`flex items-center justify-between border rounded-lg p-3 cursor-pointer hover:bg-gray-50 ${
                      form.watch('phoneType') === phone.type
                        ? 'border-primary bg-primary/5'
                        : 'border-gray-200'
                    }`}
                    htmlFor={phone.type}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value={phone.type} id={phone.type} />
                      <div className="text-gray-500">
                        {React.createElement(
                          phoneNumberTypeMapping[phone.type]?.icon,
                        )}
                      </div>
                      <span>
                        {phone.label}: {formattedNumber}
                      </span>
                    </div>
                  </Label>
                );
              })}
            </RadioGroup>
          )}

          {(isAdmin || forceOverride) && (
            <div className="space-y-2">
              <FormLabel>Override Phone Number:</FormLabel>
              <PhoneNumberInput
                source="phoneNumber"
                placeholder="Enter phone number"
                label="Phone Number"
              />
              {forceOverride && (
                <p className="text-sm text-gray-500 mt-1">
                  Demo mode is active - you can enter any phone number to test
                  the calling interface. Please do not make actual calls to
                  random numbers.
                </p>
              )}
              {form.formState.errors.overrideNumber && (
                <p className="text-red-500 text-sm mt-1">
                  {form.formState.errors.overrideNumber.message}
                </p>
              )}
            </div>
          )}

          <div>
            <FormLabel>Microphone:</FormLabel>
            <Select
              onValueChange={handleSelectDevice}
              value={form.watch('inputDevice')}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                {audioDevices.input.map((device) => (
                  <SelectItem key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {form.formState.errors.inputDevice && (
              <p className="text-red-500 text-sm mt-1">
                {form.formState.errors.inputDevice.message}
              </p>
            )}
          </div>
          <Button
            type="submit"
            disabled={
              (!form.watch('phoneType') && !form.watch('overrideNumber')) ||
              (forceOverride && !form.watch('overrideNumber'))
            }
            className="w-full"
          >
            Start Call
            </Button>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
