import { Button } from '@/modules/ui/components/button';
import React, { cloneElement } from 'react';
import {
  Form,
  required,
  useCreate,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useFormState } from 'react-hook-form';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog';
import { DateInput } from '@/modules/ui/inputs/date-input';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { FileDown, Loader2, Plus, Receipt, ScanSearch } from 'lucide-react';
import { createPortal } from 'react-dom';

const PreviewInvoiceDialog = ({
  handleClose,
  onSubmit,
  open,
  isLoading,
  data,
  registeredOn,
  isLoadingSubmit,
}) => {
  const monthYearSelector = () => {
    const options = [];
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-indexed
    const registeredDate = new Date(registeredOn);
    let registeredYear = registeredDate.getFullYear();
    let registeredMonth = registeredDate.getMonth() + 1;

    while (
      currentYear > registeredYear ||
      (currentYear === registeredYear && currentMonth >= registeredMonth)
    ) {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      options.push({
        id: `${currentMonth}/${currentYear}`,
        name: `${monthNames[currentMonth - 1]} ${currentYear}`,
      });
      currentMonth--;
      if (currentMonth === 0) {
        currentMonth = 12;
        currentYear--;
      }
    }

    return options;
  };

  return (
    <Dialog onOpenChange={handleClose} open={open}>
      <DialogContent className="overflow-y-auto max-h-[90vh] max-w-4xl">
        <DialogHeader>
          <DialogTitle>Preview Invoice</DialogTitle>
          <DialogDescription>
            Preview the invoice for the selected month and year.
          </DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit}>
          <div className="flex flex-col justify-between gap-2">
            <div className="flex flex-col gap-2">
              <SelectInput
                label="Month and Year"
                source="month-year"
                helperText={false}
                choices={monthYearSelector()}
                validate={required()}
              />
              <DateInput
                source="due_date"
                helperText={false}
                defaultValue={null}
              />
              <SwitchInput
                source="finalize_invoice"
                defaultChecked={false}
                helperText={false}
              />
              <SelectInput
                source="vendor"
                choices={[{ id: 'stripe', name: 'Stripe' }]}
                defaultValue="stripe"
                helperText={false}
                validate={required()}
              />
            </div>
            <div className="flex flex-row gap-2 justify-end">
              <SubmitButton
                isLoading={isLoading && !isLoadingSubmit}
                text="Preview"
                icon={<ScanSearch />}
                id="preview-invoice"
              />
              {
                <SubmitButton
                  isLoading={isLoadingSubmit}
                  text="Submit"
                  icon={<Receipt />}
                  id="submit-invoice"
                  disabled={data === null}
                />
              }
            </div>
          </div>
        </Form>
        {data && (
          <div className="m-4">
            <h4 className="text-lg font-semibold mb-4 border-b pb-2">
              Line Items
            </h4>
            <div className="my-4 p-4">
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Description
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Amount ($)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.line_items.map((item, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="py-4 px-6">{item.description}</td>
                        <td className="py-4 px-6">
                          {parseFloat(item.amount).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <td className="py-3 px-6 font-semibold">Total</td>
                      <td className="py-3 px-6 font-semibold">
                        {parseFloat(
                          data.line_items.reduce(
                            (sum, item) => sum + item.amount,
                            0,
                          ),
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <br />
            <h4 className="text-lg font-semibold mb-4 border-b pb-2">
              Summary by Patient
            </h4>
            <div className="my-4 p-4  ">
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        Patient Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Claims completed (#)
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Approximate Reimbursement ($)
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Vironix Charge ($)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.summary.map((row, index) => (
                      <tr key={index} className="bg-white border-b">
                        <td className="py-4 px-6">{row['Patient Name']}</td>
                        <td className="py-4 px-6">{row['count']}</td>
                        <td className="py-4 px-6">
                          {parseFloat(row['total_fee_schedule_price']).toFixed(
                            2,
                          )}
                        </td>
                        <td className="py-4 px-6">
                          {parseFloat(row['total_price']).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <td className="py-3 px-6 font-semibold">Total</td>
                      <td className="py-3 px-6 font-semibold">
                        {data.totals['count']}
                      </td>
                      <td className="py-3 px-6 font-semibold">
                        {parseFloat(
                          data.totals['total_fee_schedule_price'],
                        ).toFixed(2)}
                      </td>
                      <td className="py-3 px-6 font-semibold">
                        {parseFloat(data.totals['total_price']).toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <br />
            <h4 className="text-lg font-semibold mb-4 border-b pb-2">
              Detailed Breakdown
            </h4>
            {data.detailed_breakdown.map((patient, patientIndex) => (
              <div key={patientIndex} className="mb-4 p-4 ">
                <h5 className="text-lg font-semibold text-gray-700">
                  {patient['patient_name']}{' '}
                  <span className="text-sm text-gray-500">
                    {patient['patient_id'] ? `(${patient['patient_id']})` : ''}
                  </span>
                </h5>
                <div className="mb-2 grid grid-cols-3 gap-4 text-sm">
                  <div>
                    <span className="font-semibold">First Billable Date:</span>{' '}
                    {patient['first_billable_date']
                      ? new Date(
                          patient['first_billable_date'],
                        ).toLocaleDateString()
                      : 'N/A'}
                  </div>
                  <div>
                    <span className="font-semibold">
                      First Device Activity:
                    </span>{' '}
                    {patient['first_device_activity']
                      ? new Date(
                          patient['first_device_activity'],
                        ).toLocaleDateString()
                      : 'N/A'}
                  </div>
                  <div>
                    <span className="font-semibold">Billable:</span>{' '}
                    {!patient['should_not_bill'] ? 'Yes' : 'No'}
                  </div>
                  <div>
                    <span className="font-semibold">Volume Tier:</span>{' '}
                    {patient['tier']}
                  </div>
                </div>
                <div className="mt-2">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          CPT Code
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Units
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Approximate Reimbursement ($)
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Vironix Charge ($)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {patient['items'].map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {row['cpt_code']}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {row['units']}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {row['fee_schedule_price']
                              ? `$${parseFloat(row['fee_schedule_price']).toFixed(2)}`
                              : 'N/A'}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {row['price']
                              ? `$${parseFloat(row['price']).toFixed(2)}`
                              : 'N/A'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot className="bg-gray-50">
                      <tr>
                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase">
                          Total
                        </td>
                        <td></td>
                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-600">
                          $
                          {parseFloat(
                            patient['total_fee_schedule_price'],
                          ).toFixed(2)}
                        </td>
                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-600">
                          ${parseFloat(patient['total_price']).toFixed(2)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            ))}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const SubmitButton = ({
  isLoading,
  text,
  icon = <FileDown />,
  disabled = false,
  ...rest
}) => {
  const { isValid, isValidating, isSubmitting } = useFormState();

  const disabledState =
    isValidating || isSubmitting || !isValid || isLoading || disabled;

  return (
    <Button disabled={disabledState} type="submit" className="mt-2" {...rest}>
      {isLoading ? (
        <>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          Please wait
        </>
      ) : (
        <>
          {cloneElement(icon, { className: 'mr-2 h-4 w-4' })}
          {text}
        </>
      )}
    </Button>
  );
};

export const PreviewInvoiceButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = React.useState(false);
  const [create, { isLoading }] = useCreate();
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false);
  const notify = useNotify();
  const [data, setData] = React.useState(null);
  const refresh = useRefresh();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setData(null);
    setIsLoadingSubmit(false);
    setOpen(false);
  };

  const onSubmit = (values, e) => {
    let isPreview = true;
    if (e.nativeEvent.submitter.id === 'submit-invoice') {
      isPreview = false;
      setIsLoadingSubmit(true);
    }

    const month = values['month-year'].split('/')[0];
    const year = values['month-year'].split('/')[1];

    create(
      `providers/${record?.id}/fee-for-service-invoice`,
      {
        data: {
          month: month,
          year: year,
          is_preview: isPreview,
          due_date: values.due_date,
        },
      },
      {
        onSuccess: (response) => {
          setData(response);
          if (!isPreview) {
            handleClose();
            setIsLoadingSubmit(false);
            refresh();
          }
        },
        onError: (error) => {
          notify((error as Error)?.message, { type: 'error' });
          setIsLoadingSubmit(false);
        },
      },
    );
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        size="sm"
        className="h-8"
        variant="outline"
        type="button"
      >
        <Plus className="mr-2 h-4 w-4" />
        New Invoice
      </Button>
      {createPortal(
        <PreviewInvoiceDialog
          handleClose={handleClose}
          onSubmit={onSubmit}
          open={open}
          isLoading={isLoading}
          isLoadingSubmit={isLoadingSubmit}
          data={data}
          registeredOn={record?.registered_on}
        />,
        document.body,
      )}
    </>
  );
};
