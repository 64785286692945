import { DataTable } from '@/modules/data-table/components/data-table';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import React from 'react';
import { useListContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { columns } from './MyEnrolledPatientsTableColumns';

const EnrolledPatientsToolbar = () => {
  return (
    <DataTableToolbar
      showViewOptions={false}
      search={
        <MultiSearch
          searchFields={[
            { value: 'user.full_name', label: 'Name' },
            { value: 'id', label: 'ID' },
          ]}
        />
      }
    >
      <DataTableDateRangeFilter
        column="user.enrolled_on"
        title="Enrolled On"
        dateOnly
        align="center"
      />
    </DataTableToolbar>
  );
};

export const MyEnrolledPatientsTable = () => {
  const { data, isLoading } = useListContext();
  const navigate = useNavigate();
  return (
    <div>
      <EnrolledPatientsToolbar />
      <DataTable
        columns={columns}
        data={data || []}
        loading={isLoading}
        onRowClick={(row) => {
          navigate(`/patients/${row.id}/show`);
        }}
      />
    </div>
  );
};
