import React, { FC } from 'react';
import { List, useStore } from 'react-admin';

import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import { primaryConditions } from '@/modules/patients/constants/primaryConditions';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';

import { DataGridScroll } from '@/modules/data-table/components/data-grid-scroll';
import { DataTableDaysFilter } from '@/modules/data-table/components/data-table-days-filter';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import AppTitle from '@/modules/layout/components/app-title';
import { PatientQueueEmpty } from '@/modules/patient-queue/components/PatientQueueEmpty';
import { patientPriorityLevel } from '@/modules/patients/constants/patientPriorityLevel';
import { patientReviewStatus } from '@/modules/patients/constants/patientReviewStatus';
import ProviderField from '@/modules/ra-ui/components/ProviderField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/modules/ui/components/select';
import { Separator } from '@/modules/ui/components/separator';
import { Toggle } from '@/modules/ui/components/toggle';
import clsx from 'clsx';
import { Clipboard, ClipboardCheck } from 'lucide-react';
import { LatestMeasurementField } from '../../modules/patient-queue/components/LatestMeasurementField';
import { NameField } from '../../modules/patients/components/NameField';
import { ListUIRoot } from '@/modules/data-table/components/list-ui-root';
import { DataTableFilterButton } from '@/modules/data-table/components/data-table-filter-button';
import DataTableReferenceFilter from '@/modules/data-table/components/data-table-reference-filter';
import { DataTableFilterSingle } from '@/modules/data-table/components/data-table-filter-single';

const Toolbar = ({ view }) => (
  <DataTableToolbar
    showViewOptions={true}
    search={
      <MultiSearch
        searchFields={[
          { value: 'user.full_name', label: 'Name' },
          { value: 'id', label: 'ID' },
        ]}
      />
    }
    preferenceKey={'patient-queue.datagrid.' + view}
  >
    <DataTableFilterButton>
      <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
      {view === 'general' ? null : (
        <DataTableFilter
          column={`${view}_review_status[in]`}
          title="Review Status"
          options={[
            { label: 'Pending', value: 'pending', icon: Clipboard },
            {
              label: 'Reviewed',
              value: 'reviewed',
              icon: ClipboardCheck,
            },
          ]}
        />
      )}
      <DataTableFilter
        column="care_setting[in]"
        title="Care Setting"
        options={[
          { label: 'Home', value: 'home' },
          {
            label: 'Residential Care Facility',
            value: 'residential_care_facility',
          },
        ]}
      />
      <DataTableFilter
        column="primary_condition[in]"
        title="Primary Condition"
        options={[
          { label: 'Asthma', value: 'Asthma' },
          { label: 'COPD', value: 'COPD' },
          { label: 'Diabetes', value: 'Diabetes' },
          { label: 'General', value: 'general' },
          { label: 'Heart Failure', value: 'HeartFailure' },
          { label: 'Chronic Kidney Disease', value: 'chronic_kidney_disease' },
        ]}
      />
      <DataTableDaysFilter
        column="last_device_activity[lte]"
        title="Days since Measurement"
      />
      <DataTableFilterSingle
        column="has_device[eq]"
        title="Has Devices"
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
      />
      <DataTableReferenceFilter
        resource="users"
        column="care_team_members.practitioner_id[eq]"
        title="Care Team Member"
        optionText="full_name"
        customFilter={(name) => ({
          ...(name &&
            name.length > 1 && {
              q: { field: 'full_name', value: name },
            }),
          'role[ne]': `patient`,
          'is_provider[eq]': true,
        })}
      />
    </DataTableFilterButton>
  </DataTableToolbar>
);

const PatientQueueTable = () => {
  const [underCare, setUnderCare] = useStore(
    'patient-queue.list.under_care',
    true,
  );
  const [view, setView] = useStore('patient-queue.list.viewType', 'general');

  return (
    <ListUIRoot>
      <div className="flex items-center mb-2 gap-2">
        <Toggle
          variant="outline"
          aria-label="Toggle under care"
          onClick={() => setUnderCare(!underCare)}
          size="lg"
          className={clsx(
            'h-8 bg-white',
            underCare ? 'border-primary' : 'bg-white',
          )}
          defaultChecked={underCare}
        >
          {underCare ? 'Showing Patients in my Care' : 'Showing all Patients'}
        </Toggle>
        <Separator orientation="vertical" className="h-8" />
        <Select value={view} onValueChange={setView}>
          <SelectTrigger className="w-fit h-8">
            <SelectValue placeholder="Select view" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="general">General</SelectItem>
            <SelectItem value="rpm">RPM</SelectItem>
            <SelectItem value="ccm">CCM</SelectItem>
            <SelectItem value="pcm">PCM</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <AppTitle title="Patient Queue" />
      <List
        actions={<Toolbar view={view} />}
        title="Patient Queue"
        resource="patient-queue"
        sort={{ field: 'priority', order: 'DESC' }}
        filter={{
          'patient_status[eq]': 'active',
          ...(view !== 'general' && {
            [`has_${view}[eq]`]: true,
          }),
        }}
        exporter={false}
        queryOptions={{
          meta: {
            expand: ['profile', 'observations'],
            under_care: underCare,
          },
        }}
        sx={{
          width: '100%',
          '& .RaList-main': {
            width: '100%',
          },
        }}
        empty={<PatientQueueEmpty underCare={underCare} />}
      >
        <DataGridScroll
          preferenceKey={'patient-queue.datagrid.' + view}
          isRowSelectable={() => false}
          bulkActionButtons={false}
          rowClick={(id, _resource, _record) => `/patients/${id}/show/clinical`}
          omit={[
            'blood_glucose',
            'peak_expiratory_flow_rate',
            'forced_expiratory_volume_1',
          ]}
        >
          <BadgeField
            source="priority"
            label="Priority"
            mapping={patientPriorityLevel}
            humanize={false}
          />
          <NameField source="user.last_name" label="Patient" />
          <LuxonDateField
            source="last_device_activity"
            label="Latest measurement"
            showTime={true}
          />
          {view === 'general' ? null : (
            <LuxonDateField
              source={`${view}_last_reviewed`}
              label="Last Reviewed"
              showTime={true}
              emptyText="Never"
            />
          )}

          <LatestMeasurementField
            source="heart_rate"
            sortable={false}
            label="Heart rate (bpm)"
          />
          <LatestMeasurementField
            source="blood_pressure"
            sortable={false}
            label="Blood pressure (mmHg)"
          />
          <LatestMeasurementField
            source="body_mass"
            sortable={false}
            label="Weight (lb)"
          />
          <LatestMeasurementField
            source="oxygen_saturation"
            sortable={false}
            label="Oxygen saturation (%)"
          />
          <LatestMeasurementField
            source="blood_glucose"
            sortable={false}
            label="Blood glucose (mg/dL)"
          />
          <LatestMeasurementField
            source="peak_expiratory_flow_rate"
            sortable={false}
            label="Peak expiratory flow rate (L/min)"
          />
          <LatestMeasurementField
            source="forced_expiratory_volume_1"
            sortable={false}
            label="FEV1 (L)"
          />
          <BadgeField
            source="primary_condition"
            mapping={primaryConditions}
            applyMappedClassName={false}
            humanize={false}
          />
          <ProviderField
            source="user.tenant_id"
            label="Provider"
            sortBy={null}
            sortable={false}
          />

          {view === 'general' ? null : (
            <BadgeField
              label="Review Status"
              source={`${view}_review_status`}
              mapping={patientReviewStatus}
            />
          )}
        </DataGridScroll>
      </List>
    </ListUIRoot>
  );
};

export const PatientQueue: FC = () => {
  return (
    <div className="pb-2">
      <PatientQueueTable />
    </div>
  );
};
