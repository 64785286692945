import { Box, Stack, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useNotify, useRecordContext, useUpdate } from 'react-admin';

export function PatientNotes() {
  const [value, setValue] = useState('');
  const [lastSaved, setLastSaved] = useState(null);
  const record = useRecordContext();
  const notify = useNotify();

  useEffect(() => {
    if (record) setValue(record?.notes || '');
  }, [record]);

  const [save] = useUpdate(
    'patients',
    { id: record.id, data: { ...record, notes: value } },
    {
      onSuccess: () => {
        setLastSaved(DateTime.local());
      },
      onError: () => {
        setLastSaved(null);
        notify(`Error saving patient notes`, {
          type: 'warning',
        });
      },
    },
  );

  const handleSave = () => {
    if (record.notes !== value) save();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Stack>
      {lastSaved ? (
        <Box sx={{ alignSelf: 'end' }}>
          <span className="text-xs font-normal">Saved notes at </span>
          <span className="text-xs font-normal">
            {lastSaved.toLocaleString(DateTime.TIME_SIMPLE)}
          </span>
        </Box>
      ) : null}
      <TextField
        value={value}
        fullWidth
        multiline
        label="Notes"
        placeholder="Add any notes here"
        onChange={handleChange}
        onBlur={handleSave}
        maxRows={6}
        minRows={3}
      />
    </Stack>
  );
}
