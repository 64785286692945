import React, { useEffect } from 'react';
import { AppStorePreview } from './components/AppStorePreview';
import { GooglePLayPreview } from './components/GooglePlayPreview';
import { isAndroid } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';

const apiUrl = window.config.apiUrl;

export const Invitation = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const emailVerificationToken = searchParams.get('verifyEmailCode');

    if (emailVerificationToken) {
      fetch(apiUrl + '/auth/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: emailVerificationToken,
        }),
      });
    }
  }, [searchParams]);

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      {isAndroid ? <GooglePLayPreview /> : <AppStorePreview />}
    </div>
  );
};
