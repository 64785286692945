import React from 'react';
import {
  SelectInput,
  required,
  NumberInput,
  DateInput,
  useRecordContext,
  FormDataConsumer,
} from 'react-admin';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import { Button } from '@/modules/ui/components/button';
import { PlusIcon } from 'lucide-react';

const DemoMeasurement: React.FC = () => {
  const record = useRecordContext();
  const patientId = record?.id;

  const transform = (data) => ({
    ...data,
    patient_id: patientId,
  });

  if (!record) {
    return null;
  }

  return (
    <CreateInDialogButton
      title="Add Demo Measurement"
      resource="testing/add-device-observation"
      transform={transform}
      label="Add Demo Measurement"
      variant="default"
      notifyMessage="Demo measurement added."
      createButton={
        <Button className="h-8 bg-yellow-500 hover:bg-yellow-600">
          <PlusIcon className="mr-2 h-4 w-4" />
          Add Demo
        </Button>
      }
      refreshOnSuccess
    >
      <div className="flex flex-col gap-4">
        <SelectInput
          source="device_type"
          label="Device Type"
          choices={[
            { id: 'weight_scale', name: 'Weight Scale' },
            { id: 'blood_pressure', name: 'Blood Pressure' },
            { id: 'pulse_oximeter', name: 'Pulse Oximeter' },
            { id: 'glucometer', name: 'Glucometer' },
            { id: 'peak_flow_meter', name: 'Peak Flow Meter' },
          ]}
          validate={required()}
          helperText={false}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.device_type === 'weight_scale') {
              return (
                <NumberInput
                  source="weight"
                  label="Weight"
                  validate={required()}
                  helperText={false}
                  {...rest}
                />
              );
            }
            if (formData.device_type === 'blood_pressure') {
              return (
                <>
                  <NumberInput
                    source="systolic"
                    label="Systolic"
                    validate={required()}
                    helperText={false}
                    {...rest}
                  />
                  <NumberInput
                    source="diastolic"
                    label="Diastolic"
                    validate={required()}
                    helperText={false}
                    {...rest}
                  />
                  <NumberInput
                    source="pulse"
                    label="Pulse"
                    validate={required()}
                    helperText={false}
                    {...rest}
                  />
                </>
              );
            }
            if (formData.device_type === 'pulse_oximeter') {
              return (
                <>
                  <NumberInput
                    source="spo2"
                    label="SpO2"
                    validate={required()}
                    helperText={false}
                    {...rest}
                  />
                  <NumberInput
                    source="pulse"
                    label="Pulse"
                    validate={required()}
                    helperText={false}
                    {...rest}
                  />
                </>
              );
            }
            if (formData.device_type === 'glucometer') {
              return (
                <NumberInput
                  source="blood_glucose"
                  label="Blood Glucose"
                  validate={required()}
                  helperText={false}
                  {...rest}
                />
              );
            }
            if (formData.device_type === 'peak_flow_meter') {
              return (
                <NumberInput
                  source="peak_expiratory_flow_rate"
                  label="Peak Flow"
                  validate={required()}
                  helperText={false}
                  {...rest}
                />
              );
            }
            return null;
          }}
        </FormDataConsumer>
        <DateInput
          source="measurement_date"
          label="Measurement Date"
          validate={required()}
          helperText={false}
        />
      </div>
    </CreateInDialogButton>
  );
};

export default DemoMeasurement;
