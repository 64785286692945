import React from 'react';
import { useSidebar } from '@/modules/ui/components/sidebar';

const DRAWER_WIDTH = 240;
const CLOSED_DRAWER_WIDTH = 55;
const BUFFER = 28;

interface ListUIRootProps {
  children: React.ReactNode;
}

export const ListUIRoot: React.FC<ListUIRootProps> = ({ children }) => {
  const { open } = useSidebar();

  return (
    <div
      className={`max-w-[calc(100vw-${open ? DRAWER_WIDTH + BUFFER : CLOSED_DRAWER_WIDTH + BUFFER}px)]`}
    >
      {children}
    </div>
  );
};
