import React, { cloneElement } from 'react';
import { Button } from './button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './dropdown-menu';
import { ChevronDown } from 'lucide-react';
import { cn } from '../utils/cn';

interface SplitButtonOption {
  icon?: React.ReactElement;
  label: string;
  onClick: () => void;
}

interface SplitButtonProps {
  mainOption: SplitButtonOption;
  dropdownOptions: SplitButtonOption[];
  variant?: 'outline' | 'default';
  className?: string;
}

function SplitButton({
  variant = 'default',
  className = '',
  mainOption,
  dropdownOptions,
}: SplitButtonProps) {
  if (!dropdownOptions?.length) {
    return (
      <Button
        onClick={mainOption.onClick}
        className={cn('h-8', className)}
        variant={variant}
      >
        {mainOption.icon &&
          cloneElement(mainOption.icon, { className: 'mr-2 h-4 w-4' })}
        {mainOption.label}
      </Button>
    );
  }

  return (
    <div className="inline-flex -space-x-px divide-x divide-primary-foreground/30 rounded-lg shadow-sm shadow-black/5 rtl:space-x-reverse">
      <Button
        onClick={mainOption.onClick}
        className={cn(
          'rounded-none shadow-none first:rounded-s-lg last:rounded-e-lg focus-visible:z-10 h-8',
          className,
        )}
        variant={variant}
      >
        {mainOption.icon &&
          cloneElement(mainOption.icon, { className: 'mr-2 h-4 w-4' })}
        {mainOption.label}
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className={cn(
              'rounded-none shadow-none first:rounded-s-lg last:rounded-e-lg focus-visible:z-10 h-8 w-8',
              className,
            )}
            size="icon"
            aria-label="Options"
          >
            <ChevronDown size={16} strokeWidth={2} aria-hidden="true" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="bottom" sideOffset={4} align="end">
          {dropdownOptions.map((option) => (
            <DropdownMenuItem key={option.label} onClick={option.onClick}>
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export { SplitButton };
