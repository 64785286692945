import { SaveButton } from '@/modules/ra-ui/components/save-button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/modules/ui/components/dialog-mui';

import {
  Dialog as ShadcnDialog,
  DialogContent as ShadcnDialogContent,
  DialogTitle as ShadcnDialogTitle,
  DialogDescription as ShadcnDialogDescription,
  DialogHeader as ShadcnDialogHeader,
  DialogFooter as ShadcnDialogFooter,
} from '@/modules/ui/components/dialog';

import { cn } from '@/modules/ui/utils/cn';
import React, { ReactNode } from 'react';
import {
  EditBase,
  Form,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
} from 'react-admin';
import { get } from 'lodash';

interface EditDialogProps {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  notifyMessage?: string;
  deleteButton?: ReactNode;
  transform?: any;
  refreshOnSuccess?: boolean;
  dialogProps?: any;
  description?: string | null;
  dialogContentClassName?: string;
  closeOnClickOutside?: boolean;
  [key: string]: any;
  resource?: string;
  onSuccess?: (data) => void;
  dialogVariant?: 'mui' | 'shadcn';
}

const EditDialog: React.FC<EditDialogProps> = (props) => {
  const {
    children,
    open,
    setOpen,
    title,
    notifyMessage = 'Changes saved',
    deleteButton = null,
    transform = null,
    refreshOnSuccess = false,
    dialogProps = {},
    description = null,
    dialogContentClassName = null,
    closeOnClickOutside = true,
    saveButtonAlwaysEnable = false,
    onSuccess,
    dialogVariant = 'mui',
    stopPropagation = false,
    ...rest
  } = props;
  const resource = useResourceContext(props);
  const notify = useNotify();
  const record = useRecordContext();
  const refresh = useRefresh();

  const handleClose = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  const dialogContent = (
    <EditBase
      resource={resource}
      id={record.id}
      {...rest}
      mutationMode="pessimistic"
      transform={transform}
      mutationOptions={{
        onSuccess: (data) => {
          notify(notifyMessage);
          onSuccess && onSuccess(data);
          if (refreshOnSuccess) refresh();
          setOpen(false);
        },
        onError: (error) => {
          notify(`Error: ${get(error, 'message')}`, { type: 'error' });
        },
      }}
    >
      <Form>
        {children}
        {dialogVariant === 'shadcn' ? (
          <ShadcnDialogFooter className="mt-4">
            {deleteButton}
            <SaveButton
              variant="default"
              alwaysEnable={saveButtonAlwaysEnable}
            />
          </ShadcnDialogFooter>
        ) : (
          <DialogFooter className="mt-4">
            {deleteButton}
            <SaveButton
              variant="default"
              alwaysEnable={saveButtonAlwaysEnable}
            />
          </DialogFooter>
        )}
      </Form>
    </EditBase>
  );

  if (dialogVariant === 'shadcn') {
    return (
      <ShadcnDialog open={open} onOpenChange={setOpen}>
        <ShadcnDialogContent
          className={cn(
            'min-w-[400px] overflow-y-auto max-h-[80vh]',
            dialogContentClassName,
          )}
          onClick={(e) => (stopPropagation ? e.stopPropagation() : null)}
          onInteractOutside={
            !closeOnClickOutside
              ? (e) => {
                  e.preventDefault();
                }
              : undefined
          }
        >
          <ShadcnDialogHeader>
            <ShadcnDialogTitle>{title}</ShadcnDialogTitle>
            {description && (
              <ShadcnDialogDescription>{description}</ShadcnDialogDescription>
            )}
          </ShadcnDialogHeader>
          {dialogContent}
        </ShadcnDialogContent>
      </ShadcnDialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={closeOnClickOutside ? handleClose : null}
      {...dialogProps}
      onClick={(e) => (stopPropagation ? e.stopPropagation() : null)}
    >
      <DialogContent
        onClose={handleClose}
        className={cn(
          'min-w-[400px] overflow-y-auto max-h-[80vh]',
          dialogContentClassName,
        )}
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          {description && <DialogDescription>{description}</DialogDescription>}
        </DialogHeader>
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
};
export default EditDialog;
